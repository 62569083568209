import React from 'react';
import TextField from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Rating from '@mui/material/Rating';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import { API_post_file_upload_with_eagerly_generated_presigned_url } from '../../../constants';
import { getDefaultAxiosConfig } from '../requests/utilities/requests';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

function InputEntry(props) {

  const [entryData, setEntryData] = React.useState({
    value_text: "",
    value_integer: null,
    value_float: null,
    file_upload_uuids: [],
  });

  const [isFileLoading, setIsFileLoading] = React.useState(false)

  console.log("Is File Loading", isFileLoading)

  const handleRatingChange = (event, newValue) => {
    setEntryData(prevState => ({
      ...prevState,
      value_float: newValue
    }));
  };

  const handleDeleteFile = (e) => {
    //  props.delete_file_upload(entryData.file_upload_uuids?.[0])
    setEntryData(prevState => ({
      ...prevState,
      file_upload_uuids: []
    }))
  }

  const uploadFile = async (media, type) => {
    const formData = new FormData();
    formData.append("company_id", props.homeCompany);
    formData.append("file_name", media.name);
    formData.append("type", type);

    setIsFileLoading(true)

    // [1] Get presigned URL
    const axiosConfig = await getDefaultAxiosConfig();
    const res = await axios.post(API_post_file_upload_with_eagerly_generated_presigned_url, formData, {
      headers: {
        ...axiosConfig.headers,
        "Content-Type": "multipart/form-data",
      },
    });
    const fileUUID = res?.data?.redux?.payload?.uuid;
    const presignedUrl = res?.data?.redux?.payload?.presigned_url;

    // [2] Upload file
    await axios.put(presignedUrl, media, {
      headers: {
        'Content-Type': media.type,
      }
    });

    setIsFileLoading(false)

    // [3] Get file URL
    return fileUUID;
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileUUID = await uploadFile(file, "other");
    setEntryData((prevEntryData) => ({
      ...prevEntryData,
      file_upload_uuids: [fileUUID],
    }));
  };

  console.log("ENTRY INPUT", entryData)


  return (
    <div className="w-full">
      {props.categoryType === "star" &&
        <div>
          <Rating
            value={entryData.value_float}
            onChange={handleRatingChange}
          />
        </div>
      }
      {props.categoryType === "dollar" &&
        <div className="mt-3 w-1/3">
          <TextField
            fullWidth
            placeholder="1000"
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              sx: {
                backgroundColor: "white",
                borderRadius: "10px",
                height: "45px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              },
            }}
            value={entryData.value_float}
            onChange={(e) => setEntryData(prevState => ({
              ...prevState,
              value_float: e.target.value
            }))}
          />
        </div>
      }
      {props.categoryType === "percentage" &&
        <div className="mt-3 w-1/3">
          <TextField
            fullWidth
            placeholder="32.5"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              sx: {
                backgroundColor: "white",
                borderRadius: "10px",
                height: "45px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              },
            }}
            value={entryData.value_float}
            onChange={(e) => setEntryData(prevState => ({
              ...prevState,
              value_float: e.target.value
            }))}
          />
        </div>
      }
      {props.categoryType === "number" &&
        <div className="mt-3 w-1/3">
          <TextField
            fullWidth
            placeholder="15"
            size="small"
            InputProps={{
              sx: {
                backgroundColor: "white",
                borderRadius: "10px",
                height: "45px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              },
            }}
            value={entryData.value_float}
            onChange={(e) => setEntryData(prevState => ({
              ...prevState,
              value_float: e.target.value
            }))}
          />
        </div>
      }
      <div className={props.categoryType === "text" ? "" : "mt-3"}>
        <TextField
          fullWidth
          placeholder={`${props.categoryType === "text" ? "Enter text here..." : "Add note (optional)"}`}
          size="small"
          multiline
          rows={3}
          InputProps={{
            sx: {
              backgroundColor: "white",
              borderRadius: "12px",
              paddingY: "10px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#6d28d9",
              },
            },
          }}
          value={entryData.value_text}
          onChange={(e) => setEntryData(prevState => ({
            ...prevState,
            value_text: e.target.value
          }))}
        />
      </div>
      <div className="mt-3 w-full flex justify-between">
        {entryData.file_upload_uuids?.length === 0 ?
          <div>
            <label htmlFor={`file-upload-${props.fileKey}`} className="text-slate-500 cursor-pointer">
              <span>{!isFileLoading ? <AttachFileIcon /> : <CircularProgress size="1rem" sx={{  color: "#6d28d9", marginTop: "4px" }} />}</span>
            </label>
            <input
              id={`file-upload-${props.fileKey}`}
              name={`file-upload-${props.fileKey}`}
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept=".jpg, .jpeg, .png"
            />
          </div> :
          <div className="flex space-x-2">
            <div
              className="text-slate-500 hover:text-slate-500 cursor-pointer"
              onClick={() => handleDeleteFile()}
            >
              <CloseIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
            </div>
            <div
              className="text-violet-700 hover:text-violet-900 text-sm cursor-pointer"
              onClick={() => props.get_file_upload(entryData?.file_upload_uuids?.[0])}
            >
              <span><ImageIcon style={{ fontSize: "18px" }} /></span> 1 Image Attached
            </div>
          </div>
        }
        {!isFileLoading ?
          <div
            className="px-4 py-1.5 bg-violet-700 hover:bg-violet-800 text-white rounded-lg cursor-pointer"
            onClick={() => {
              const sanitizedValueFloat = entryData.value_float
                ? typeof entryData.value_float === 'string'
                    ? entryData.value_float.replace(/,/g, '')
                    : entryData.value_float
                : null;
              const sanitizedValueInteger = entryData.value_integer ? entryData.value_integer.replace(/,/g, '') : null;
              props.post_logbook_category_entry(
                props.category.uuid,
                entryData.value_text,
                sanitizedValueInteger,
                sanitizedValueFloat,
                entryData.file_upload_uuids
              );
              setEntryData({ value_text: "", value_integer: null, value_float: null,  file_upload_uuids: []})
              props.setShowInput(false)
            }}
          >
            Save Entry
          </div> :
          <div
            className="px-4 py-1.5 bg-slate-200 text-slate-400 rounded-lg"
          >
            Save Entry
          </div>
        }
      </div>
    </div>
  );
}

export default InputEntry;
