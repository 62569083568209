import React from "react";
import ShiftInputsModal from "./ShiftInputsModal";
import NoCapableRolesWarningModal from "./NoCapableRolesWarningModal";
import { get_t_str_shortened } from "../../../../helpers/helpers";

function OtherStoreCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  // Function for opening the modal
  const handleOpen = () => setOpen(true);

  // Function for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
  };

  // Default shift info
  const shift_info = {
    adjustment_type: "no_adjust",
    break_length: 0,
    break_start: 12,
    date: props.date_str,
    hours: 4,
    id: 0,
    person: props.p.scheduling_app_user_id,
    role_id: props.p.main_role_id,
    role_type: props.p.role_type,
    station_id: null,
    station: null,
    shift_end: 16,
    shift_length: 4,
    shift_start: 12,
    store: props.store_id,
    store_team_member: props.p.id,
    note: null,
  };

  // Logic for determining the break in the New Shift component
  // Assuming availability_info is an array that may be empty or contain objects without defined properties
  const availableFrom =
    (props.availability_info && props.availability_info["available_from"]) || 0;
  const availableTo =
    (props.availability_info && props.availability_info["available_to"]) || 24;
  const availableTimeSpan = availableTo - availableFrom;

  let shiftStart = availableFrom;
  let shiftEnd = availableTo;

  if (availableTimeSpan > 8) {
    shiftEnd = shiftStart + 8;
  }

  let breakLength = 0;
  let breakStart = shiftStart;

  // Only set a break if the available time span is more than the threshold
  if (availableTimeSpan >= 6) {
    // Decide on the break length based on the available time span
    breakLength = availableTimeSpan > 6 ? 0.5 : 0.5;

    // Calculate the break start time based on the middle of the shift
    breakStart =
      Math.round(((shiftEnd + shiftStart) / 2) * 4) / 4 - breakLength / 2;
  }

  let dynamicShiftInfo = shift_info;
  if (availableFrom !== 0 || availableTo !== 24) {
    // Construct the dynamic shift object
    dynamicShiftInfo = {
      ...shift_info, // Keep other default shift_info properties
      shift_start: shiftStart,
      shift_end: shiftEnd,
      shift_length: shiftEnd - shiftStart,
      break_length: breakLength,
      break_start: breakStart,
      hours: shiftEnd - shiftStart - breakLength,
      // Other properties (id, person, role_id, role_type, store) should be derived from props as before
    };
  }

  const [openNoCapableRolesWarning, setOpenNoCapableRolesWarning] =
    React.useState(false);
  const handleOpenNoCapableRolesWarning = () => {
    setOpenNoCapableRolesWarning(true);
  };
  const handleCloseNoCapableRolesWarning = () => {
    setOpenNoCapableRolesWarning(false);
  };

  console.log("AAA", props.other_store_shift_info)

  return (
    <>
      <div
        className={`w-full h-full ${props.day_in_past ? "": "cursor-pointer"}`}
        onClick={
          !props.day_in_past && (props.hasNoCapableRoles ? handleOpenNoCapableRolesWarning : handleOpen)
        }
      >
        <div className="w-full h-full flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md">
          <div className="w-full h-full flex items-center py-3 px-3 bg-slate-200">
            <div className="text-slate-400 text-xs mt-0.5">
              {!props.minimizeMode &&
                <div className="xl:hidden">
                  {props.other_store_shift_info?.length > 1 ? <p>{props.other_store_shift_info?.length} shifts</p> : <p>{`${get_t_str_shortened(props.other_store_shift_info?.[0]?.shift_start)} - ${get_t_str_shortened(props.other_store_shift_info?.[0]?.shift_end)}`}</p>}
                </div>
              }
              <p className="text-xxs">@ {props.other_store_shift_info?.[0]?.store_name}</p>
            </div>
          </div>
        </div>
      </div>
      <ShiftInputsModal
        open={open}
        handleClose={handleClose}
        shift_info={dynamicShiftInfo}
        shifts_info={[dynamicShiftInfo]}
        p={props.p}
        new_shift={true}
        addShift={props.addShift}
        date_name={props.date_name}
        get_t_str={props.get_t_str}
        availability_info={props.availability_info}
        role_names={props.role_names}
        saveShiftFinished={props.saveShiftFinished}
        setSaveShiftFinished={props.setSaveShiftFinished}
        d={props.d}
        role_types={props.role_types}
        addMultipleShifts={props.addMultipleShifts}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        frequentShifts={props.frequentShifts}
        breakWaiverEnabled={props.breakWaiverEnabled}
        defaultBreakLengthHours={props.defaultBreakLengthHours}
        breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
      />
      <NoCapableRolesWarningModal
        open={openNoCapableRolesWarning}
        handleClose={handleCloseNoCapableRolesWarning}
        p={props.p}
        role_names={props.role_names}
        updatePersonInfo={props.updatePersonInfo}
      />
    </>
  );
}

export default OtherStoreCard;
