import React from "react";
import DateCardSalesInfo from "./DateCardSalesInfo";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import WeatherEventsCard from "./WeatherEventsCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaidIcon from "@mui/icons-material/Paid";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import Tooltip from "@mui/material/Tooltip";
import EventsListModal from "./EventsListModal";
import HoursOfOpsButton from "./daily_view/HoursOfOpsButton";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

function DateCard(props) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const toggleDropdown = (event) => {
    // Check if the MoreVertIcon was clicked
    const isMoreVertIconClicked = event.target.classList.contains(
      "more-vert-icon-class"
    ); // Replace with the actual class name

    if (isMoreVertIconClicked) {
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
    }
  };

  function toggleLocked() {
    props.lock_day(props.d);
  }

  // Ref to the dropdown container
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleClick = (event) => {
      // Close the dropdown if the click is outside of it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add click event listener when the component mounts
    document.addEventListener("click", handleClick);

    // Remove click event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // Opening forecast adjusment modal
  const [openForecastAdjustment, setOpenForecastAdjustment] =
    React.useState(false);
  const handleOpenForecastAdjustment = () => setOpenForecastAdjustment(true);
  const handleCloseForecastAdjustment = () => setOpenForecastAdjustment(false);

  // Opening forecast adjusment modal
  const [openHoursOfOps, setOpenHoursOfOps] = React.useState(false);
  const handleOpenHoursOfOps = () => setOpenHoursOfOps(true);
  const handleCloseHoursOfOps = () => setOpenHoursOfOps(false);

  // Opening notes modal
  const [openNotes, setOpenNotes] = React.useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);
  const [flagged, setFlagged] = React.useState(
    props.daily_forecast_exclude_date.length > 0
  );

  // Handle flagging day
  function toggleFlagged() {
    if (flagged) {
      props.deleteForecastExcludeDate(props.date_str);
      setFlagged(false);
    } else {
      const data = {
        date: props.date_str,
        reason: null,
      };
      props.saveForecastExcludeDate(data);
      setFlagged(true);
    }
  }

  // Opening eventsList modal
  const [openEventsList, setOpenEventsList] = React.useState(false);
  const handleOpenEventsList = () => setOpenEventsList(true);
  const handleCloseEventsList = () => setOpenEventsList(false);

  //const eventsList = [{title: "Golden State Warriors vs Chicago Bulls", description: "NBA game featuring Steph Curry and Zach Lavine. Warriors are 3-2 in this matchup.", distance_away: "10km", impact: "high"}, {title: "SF 49ers vs Miami Dolphins", description: "NFL game with playoff implications. Estimated attendance is 80,000 people.", distance_away: "10km", impact: "high"}]
  const eventsList = props.daily_local_events?.filter((i) => i.distance <= 15);

  const [
    locked,
    date,
    date_str,
    date_dow,
    date_name,
    todays_date_yes,
    dow_name,
    day_shifts,
    day_request_off,
    dow_availability,
    edit_mode,
    day_in_past,
    forecasted_labor_day,
    forecasted_workload_day,
    recommended_schedule_labor_day,
    actual_schedule_labor_day,
    calculated_earned_labor_day,
    recommended_used_labor_vs_earned_day,
    actual_used_labor_vs_earned_day,
    adjustment_chosen,
    forecast_sales,
    actual_sales,
    dow_default_operating_hours,
    day_operating_hours,
    day_time_off_requests,
    historical_sales_context,
    day_other_store_shifts,
  ] = props.get_date_info(props.d);

  return (
    <>
      <div
        className={`${props.isFixed ? "-ml-2 pl-2 pr-2 pt-3" : ""} ${
          props.i === 6 && props.isFixed ? "-mr-10 pr-12" : ""
        } bg-white h-full`}
      >
        <div className={`text-base xl:text-sm lg:text-xs`}>
          <div className="rounded flex items-center justify-between min-w-max">
            <h2
              className={`font-bold cursor-pointer ${
                props.todays_date_yes
                  ? "text-violet-700 hover:text-violet-800"
                  : "text-slate-900 hover:text-violet-800"
              }`}
              onClick={() => props.toggleDailyWeekly(props.d)}
            >
              {props.date_name}
            </h2>
            <div className="relative inline-block text-left">
              <div
                className={`more-vert-icon-class w-4 h-6 flex items-center justify-center hover:bg-slate-50 rounded cursor-pointer ${
                  props.todays_date_yes ? "text-violet-700" : "text-slate-500"
                }`}
                onClick={(event) => {
                  toggleDropdown(event);
                  event.stopPropagation();
                }}
              >
                <MoreVertIcon style={{ fontSize: "20px" }} />
              </div>
            </div>
            {isDropdownOpen && (
              <div
                className="absolute top-5 right-0 mt-2 w-[230px] rounded-lg shadow-xl bg-white border border-slate-200 z-30 text-slate-500"
                ref={dropdownRef}
              >
                <div
                  className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                  onClick={(event) => {
                    props.toggleDailyWeekly(props.d);
                    toggleDropdown(event);
                  }}
                >
                  <div className="text-slate-600">
                    <VisibilityIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="text-sm">View daily schedule</p>
                </div>
                {!props.day_in_past && (
                  <div
                    className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                    onClick={(event) => {
                      handleOpenForecastAdjustment();
                      toggleDropdown(event);
                    }}
                  >
                    <div className="text-slate-600">
                      <PaidIcon
                        style={{ fontSize: "18px", marginBottom: "2px" }}
                      />
                    </div>
                    <p className="text-sm">Edit forecasted sales</p>
                  </div>
                )}
                <div
                  className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                  onClick={(event) => {
                    toggleFlagged();
                    toggleDropdown(event);
                  }}
                >
                  <div className="text-slate-600">
                    {flagged ? (
                      <FlagOutlinedIcon
                        style={{ fontSize: "18px", marginBottom: "2px" }}
                      />
                    ) : (
                      <FlagIcon
                        style={{ fontSize: "18px", marginBottom: "2px" }}
                      />
                    )}
                  </div>
                  <p className="text-sm">
                    {flagged
                      ? "Unflag this day as outlier"
                      : "Flag this day as outlier"}
                  </p>
                </div>
                {!props.day_in_past && (
                  <div
                    className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                    onClick={(event) => {
                      toggleLocked();
                      toggleDropdown(event);
                    }}
                  >
                    <div className="text-slate-600">
                      {props.locked ? (
                        <LockOpenOutlinedIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      ) : (
                        <LockIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      )}
                    </div>
                    <p className="text-sm">
                      {props.locked ? "Unlock this day" : "Lock this day"}
                    </p>
                  </div>
                )}
                <div
                  className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                  onClick={(event) => {
                    handleOpenNotes();
                    toggleDropdown(event);
                  }}
                >
                  <div className="text-slate-600">
                    <NoteAltIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="text-sm">
                    {props.daily_events?.length > 0 ? "Notes" : "Add a note"}
                  </p>
                </div>
                <div
                  className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                  onClick={(event) => {
                    handleOpenHoursOfOps();
                    toggleDropdown(event);
                  }}
                >
                  <div className="text-slate-600">
                    <WatchLaterIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="text-sm">Adjust hours of operation</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* {!props.minimizeMode && */}
        <div>
          <DateCardSalesInfo
            view="weekly"
            forecast_sales={props.forecast_sales}
            actual_sales={props.actual_sales}
            date_name={props.date_name}
            day_of_week_str={props.day_of_week_str}
            edit_mode={props.edit_mode}
            lock_day={props.lock_day}
            locked={props.locked}
            d={props.d}
            day_in_past={props.day_in_past}
            todays_date_yes={props.todays_date_yes}
            adjustment_chosen={props.adjustment_chosen}
            addForecastAdjustment={props.addForecastAdjustment}
            addAdjustmentFinished={props.addAdjustmentFinished}
            date_str={props.date_str}
            historical_sales_context={props.historical_sales_context}
            openForecastAdjustment={openForecastAdjustment}
            handleOpenForecastAdjustment={handleOpenForecastAdjustment}
            handleCloseForecastAdjustment={handleCloseForecastAdjustment}
            setOpenForecastAdjustment={setOpenForecastAdjustment}
            minimizeMode={props.minimizeMode}
            windowWidth={props.windowWidth}
            defaultHours={dow_default_operating_hours?.[0]}
            specialHours={day_operating_hours?.[0]}
            hoo={
              day_operating_hours.length > 0
                ? day_operating_hours[0]
                : dow_default_operating_hours[0]
            }
          />
        </div>
        {/* } */}
        <div className="w-full flex justify-between mt-2">
          <div>
            <WeatherEventsCard
              view="weekly"
              element="weather"
              daily_weather={props.daily_weather}
              daily_events={props.daily_events}
              daily_local_events={props.daily_local_events}
              date_str={props.date_str}
              date_name={props.date_name}
              saveEvent={props.saveEvent}
              deleteEvent={props.deleteEvent}
              saveEventFinished={props.saveEventFinished}
              setSaveEventFinished={props.setSaveEventFinished}
              openNotes={openNotes}
              handleOpenNotes={handleOpenNotes}
              handleCloseNotes={handleCloseNotes}
              setOpenNotes={setOpenNotes}
              minimizeMode={props.minimizeMode}
            />
          </div>
          {!props.minimizeMode && (
            <div className="flex items-center justify-end space-x-1.5">
              {eventsList.length > 0 && (
                <div
                  className="text-slate-500 hover:text-violet-700 cursor-pointer"
                  onClick={handleOpenEventsList}
                >
                  <Tooltip
                    title={`There ${eventsList.length === 1 ? "is" : "are"} ${
                      eventsList.length
                    } local ${
                      eventsList.length === 1 ? "event" : "events"
                    } today. Click to see`}
                  >
                    <LocalActivityIcon
                      style={{ fontSize: "14px", marginBottom: "8px" }}
                    />
                  </Tooltip>
                </div>
              )}
              {props.daily_events?.length > 0 && (
                <div
                  className="text-slate-500 hover:text-violet-700 cursor-pointer"
                  onClick={handleOpenNotes}
                >
                  <Tooltip
                    title={`You have ${props.daily_events?.length} ${
                      props.daily_events?.length === 1 ? "note" : "notes today"
                    }. Click to see`}
                  >
                    <NoteAltIcon
                      style={{ fontSize: "14px", marginBottom: "8px" }}
                    />
                  </Tooltip>
                </div>
              )}
              {props.locked && (
                <div
                  className="text-slate-500 hover:text-violet-700 cursor-pointer"
                  onClick={toggleLocked}
                >
                  <Tooltip title="You have locked this day. This will prevent any changes to this day when any autoscheduler is run. Click to unlock.">
                    <LockIcon
                      style={{ fontSize: "14px", marginBottom: "8px" }}
                    />
                  </Tooltip>
                </div>
              )}
              {flagged && (
                <div
                  className="text-slate-500 hover:text-violet-700 cursor-pointer"
                  onClick={toggleFlagged}
                >
                  <Tooltip title="You have flagged this day as an outlier. This will prevent this day's sales from being factored into the forecasting algorithm in the future. Click to unflag.">
                    <FlagIcon
                      style={{ fontSize: "14px", marginBottom: "8px" }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <EventsListModal
        open={openEventsList}
        handleClose={handleCloseEventsList}
        date_str={props.date_str}
        eventsList={eventsList}
      />
      <HoursOfOpsButton
        date_name={date_name}
        hoo={
          day_operating_hours.length > 0
            ? day_operating_hours[0]
            : dow_default_operating_hours[0]
        }
        get_t_str={props.get_t_str}
        dow_name={dow_name}
        edit_hoo={props.edit_hoo}
        default_checked={day_operating_hours.length > 0 ? false : true}
        start_qhnum={props.start_qhnum}
        open={openHoursOfOps}
        handleClose={handleCloseHoursOfOps}
        view="weekly"
      />
    </>
  );
}

export default DateCard;
