import React from "react";
import TextField from "@mui/material/TextField";
import { formatDateReadable } from "../../utilities/helpers.js";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatDate } from "../../utilities/helpers.js";
import { text_to_num } from "../../utilities/helpers.js";
import { format_time_num } from "../../utilities/helpers.js";
import DefaultAvailabilityCardUpdated from "./DefaultAvailabilityCardUpdated.js";
import { Drawer } from "@mui/material";
import DrawerHeader from "../helpers/DrawerHeader.js";
import DrawerFooterContainerSmall from "../helpers/DrawerFooterContainerSmall.js";
import ButtonFilled from "../helpers/ButtonFilled.js";
import { PurpleSwitch } from "../../../../../helpers/helpers.js";
import DatePickerDropdown from "../helpers/DatePickerDropdown.js";
import { generateTimeOptions } from "../../../../../helpers/helpers.js";
import { filterOptions } from "../../../../../helpers/helpers.js";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

export default function AvailabilityMineTableRow(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState("All Day");

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  const [date, setDate] = React.useState(formatDate(props.minEffectiveDate));

  const [note, setNote] = React.useState("");

  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");

  const [startTime2, setStartTime2] = React.useState("");
  const [endTime2, setEndTime2] = React.useState("");

  const [enterSplitAvailability, setEnterSplitAvailability] = React.useState(false);

  function handleSplitAvailabilityChecked(e) {
    const { checked } = e.target;
    setEnterSplitAvailability(checked);
  }

  const [preferredFrom, setPreferredFrom] = React.useState("");
  const [preferredTo, setPreferredTo] = React.useState("");

  const [validationError, setValidationError] = React.useState("");

  const [addPreferences, setAddPreferences] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setAddPreferences(event.target.checked);
    setPreferredFrom("");
    setPreferredTo("");
  };

  const [selectedAvailability, setSelectedAvailability] = React.useState("");

  const [preferencesChecked, setPreferencesChecked] = React.useState(false);

  const handlePreferencesChange = (event) => {
    setPreferencesChecked(event.target.checked);
  };


  React.useEffect(() => {
    setValidationError("");
  }, [
    props.isModalVisible,
    startTime,
    startTime2,
    endTime,
    endTime2,
    preferredFrom,
    preferredTo,
    selectedAvailability,
    enterSplitAvailability,
    preferencesChecked,
    note
  ]);

  console.log("SELECTED AVAILABILITY", selectedAvailability);

  // props.delete_availability_request

  const handleSubmit = () => {
    let days = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];

    if (selectedAvailability === "Custom") {
      if (!startTime || !endTime) {
        setValidationError(
          "Both start and end times are required for custom availability."
        );
        return;
      }

      const startTimeNum = text_to_num(startTime);
      const endTimeNum = text_to_num(endTime);

      if (startTimeNum >= endTimeNum) {
        setValidationError("Start time must be before end time.");
        return;
      }

      let availableRanges = [[startTimeNum, endTimeNum]];

      if (enterSplitAvailability) {
        if (!startTime2 || !endTime2) {
          setValidationError(
            "Both start and end times are required for split availability."
          );
          return;
        }

        const startTime2Num = text_to_num(startTime2);
        const endTime2Num = text_to_num(endTime2);

        if (startTime2Num >= endTime2Num) {
          setValidationError("Second start time must be before second end time.");
          return;
        }

        if (endTimeNum >= startTime2Num) {
          setValidationError("Split availability must be later than the first availability range.");
          return;
        }

        availableRanges.push([startTime2Num, endTime2Num]);
      }

      if (preferencesChecked) {
        if (!preferredFrom || !preferredTo) {
          setValidationError(
            "Both Preferred From and Preferred To are required when preferences are checked."
          );
          return;
        }

        const preferredFromNum = text_to_num(preferredFrom);
        const preferredToNum = text_to_num(preferredTo);

        if (preferredFromNum >= preferredToNum) {
          setValidationError("Preferred From must be before Preferred To.");
          return;
        }

        // Check if preferred times are within any of the availability ranges
        const isPreferredWithinAvailable = availableRanges.some(([start, end]) =>
          preferredFromNum >= start && preferredToNum <= end
        );

        if (!isPreferredWithinAvailable) {
          setValidationError(
            "Preferred times must be within the custom availability range."
          );
          return;
        }
      }
    }

    const data = {
      dow: days.indexOf(props.day) + 1,
      available_from:
        selectedAvailability === "Custom" ? text_to_num(startTime) : 0,
      available_to:
        selectedAvailability === "Custom"
          ? text_to_num(endTime)
          : selectedAvailability === "All Day"
          ? 24
          : 0,
      available_from_2: (selectedAvailability === "Custom" && enterSplitAvailability) ? text_to_num(startTime2) : null,
      available_to_2: (selectedAvailability === "Custom" && enterSplitAvailability) ? text_to_num(endTime2) : null,
      preferred_from: preferredFrom ? text_to_num(preferredFrom) : 0,
      preferred_to: preferredTo ? text_to_num(preferredTo) : 0,
      note: note,
      activated_at: date,
    };
    console.log("DATA", data);
    props.create_availability_request(data);
    handleClose();
    setDrawerOpen(false);
  };

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  function resetState() {
    setDate(formatDate(props.minEffectiveDate))
    setStartTime("")
    setEndTime("")
    setStartTime2("")
    setEndTime2("")
    setEnterSplitAvailability(false)
    setPreferredFrom("")
    setPreferredTo("")
    setValidationError("")
    setSelectedAvailability("")
    setPreferencesChecked(false)
    setNote("")
  }

  React.useEffect(() => {
    resetState()
  }, [isDrawerOpen])


  // For the effective date datepicker
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDatePicker() {
    setShowDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]); // Re-run the effect when 'showDatePicker' changes

  // Modify your existing TextField components to use Autocomplete
  function TimeRangeInput({
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
    startPlaceholder = "Start Time",
    endPlaceholder = "End Time",
    startQhnum
  }) {
    return (
      <div className="mt-2 w-full flex items-center relative bg-slate-100 rounded-2xl p-2">
        <div className="w-[42%]">
          <FormControl fullWidth size="small">
            <Autocomplete
              id="start-time"
              disableClearable
              options={generateTimeOptions(startQhnum)}
              value={startTime}
              onChange={(event, newValue) => {
                onStartTimeChange(newValue);
              }}
              filterOptions={filterOptions}
              size="small"
              onKeyDown={(event) => {
                if (
                  event.key === "-" ||
                  event.key === "Enter" ||
                  event.key === "Tab"
                ) {
                  event.preventDefault();
                  const firstOption = filterOptions(
                    generateTimeOptions(startQhnum),
                    { inputValue: event.target.value }
                  )[0];

                  if (firstOption) {
                    onStartTimeChange(firstOption);
                  }
                }
              }}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={startPlaceholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px !important",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      backgroundColor: "white",
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="text-slate-500 w-[16%] flex items-center justify-center">
          <ArrowForwardIcon />
        </div>
        <div className="w-[42%]">
          <FormControl fullWidth size="small">
            <Autocomplete
              id="end-time"
              disableClearable
              options={generateTimeOptions(startQhnum)}
              value={endTime}
              onChange={(event, newValue) => {
                onEndTimeChange(newValue);
              }}
              filterOptions={filterOptions}
              size="small"
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" ||
                  event.key === "Tab"
                ) {
                  event.preventDefault();
                  const firstOption = filterOptions(
                    generateTimeOptions(startQhnum),
                    { inputValue: event.target.value }
                  )[0];

                  if (firstOption) {
                    onEndTimeChange(firstOption);
                  }
                }
              }}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={endPlaceholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px !important",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      backgroundColor: "white",
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      </div>
    );
  }

  return (
    <>
      <tr key={props.day}>
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-slate-900">
          {props.day}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900 flex flex-col items-start justify-start">
          <div className="w-full">
            <DefaultAvailabilityCardUpdated
              day={props.day}
              availability={props.availability}
              availability2={props.availability2}
              preferences={props.preferences}
              status="current"
              inEffect={props.inEffect}
              permissionRole={props.permissionRole}
            />
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
          {props.requested && props.requested.length > 0 && (
            <div className="flex flex-col space-y-2 mb-2">
              {props.requested.map((request, index) => (
                <DefaultAvailabilityCardUpdated
                  key={index}
                  day={props.day}
                  availability={request.availability}
                  availability2={request.availability2}
                  preferences={request.preferences}
                  status={request.status}
                  inEffect={request.inEffect}
                  permissionRole={props.permissionRole}
                  id={request.id}
                  note={request.note}
                  rescind_availability_request={
                    props.rescind_availability_request
                  }
                />
              ))}
            </div>
          )}
          <div
            className="w-full h-16 rounded-xl flex items-center justify-center border border-dashed border-slate-200 hover:border-slate-900 cursor-pointer"
            onClick={toggleDrawer(true)}
          >
            <p className="text-violet-700 text-xs">Request New Availability</p>
          </div>
        </td>
        <td className="whitespace-nowrap pl-3 pr-6 py-4 text-sm text-slate-500 text-right">
          {props.requested && props.requested.length > 0 && (
            <div>
              {props.requested.map((request, index) => (
                <div className="flex flex-col space-y-2">
                  <div className="text-slate-900 h-16 flex items-center justify-end">
                    {formatDateReadable(request.inEffect)}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="w-full h-16"></div>
        </td>
      </tr>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-screen bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={`${props.day} New Request`}
              onClose={toggleDrawer(false)}
            />
            <div className="px-6">
              <h3 className="text-slate-900 text-base">Availability</h3>
              <p className="text-slate-500 mt-2">
                Choose your desired availability for {props.day}
              </p>
              <div className="mt-3 pb-2 border-b border-slate-200">
                <div className="bg-white border border-slate-200 rounded-2xl overflow-hidden">
                  <div
                    className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("Unavailable")}
                  >
                    <p className="text-slate-900 text-sm">Unavailable</p>
                    {selectedAvailability === "Unavailable" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                  <div
                    className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("All Day")}
                  >
                    <p className="text-slate-900 text-sm">All Day</p>
                    {selectedAvailability === "All Day" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                  <div
                    className="px-4 h-14 hover:bg-violet-50 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("Custom")}
                  >
                    <p className="text-slate-900 text-sm">Custom</p>
                    {selectedAvailability === "Custom" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  {selectedAvailability === "Custom" && (
                    <div className="w-full flex items-center justify-between mb-6">
                      <div className="w-full">
                        <div>
                          <p className="text-slate-500">
                            Set custom availability for {props.day}
                          </p>
                        </div>
                        <TimeRangeInput
                          startTime={startTime}
                          endTime={endTime}
                          onStartTimeChange={setStartTime}
                          onEndTimeChange={setEndTime}
                          startQhnum={0} // Ensure this prop is passed
                        />
                        <div className="mt-5 flex items-center">
                          <PurpleSwitch
                            id="split-availability"
                            name="split_availability"
                            checked={enterSplitAvailability}
                            onChange={handleSplitAvailabilityChecked}
                          />
                          <p className="text-slate-500 text-sm">
                            Split availability
                          </p>
                        </div>
                        {enterSplitAvailability && (
                          <TimeRangeInput
                            startTime={startTime2}
                            endTime={endTime2}
                            onStartTimeChange={setStartTime2}
                            onEndTimeChange={setEndTime2}
                            startPlaceholder="Start Time"
                            endPlaceholder="End Time"
                            startQhnum={0}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {selectedAvailability !== "Unavailable" && (
              <div className="px-6">
                <div className="pt-6 pb-6 border-b border-slate-200">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <h3 className="text-slate-900 text-base">Preferences</h3>
                      <p className="text-slate-500 mt-2">
                        Set your preferences (optional)
                      </p>
                    </div>
                    <PurpleSwitch
                      checked={preferencesChecked}
                      onChange={handlePreferencesChange}
                    />
                  </div>
                  {preferencesChecked && (
                    <TimeRangeInput
                      startTime={preferredFrom}
                      endTime={preferredTo}
                      onStartTimeChange={setPreferredFrom}
                      onEndTimeChange={setPreferredTo}
                      startPlaceholder="Preferred From"
                      endPlaceholder="Preferred To"
                      startQhnum={0}
                    />
                  )}
                </div>
                {validationError && (
                  <div className="pt-5">
                    <p className="text-rose-600 text-sm">{validationError}</p>
                  </div>
                )}
              </div>
            )}
            <div className="px-6">
              <div className="py-5 border-b border-slate-200">
                <div>
                  <h3 className="text-slate-900 text-base">Effective Date</h3>
                  <p className="text-slate-500 mt-2">
                    When do you want this availability to go into effect?
                  </p>
                </div>
                <div className="w-full mt-3">
                  <div className="p-2 bg-slate-100 rounded-2xl">
                    <DatePickerDropdown
                      showDailyDatePicker={showDatePicker}
                      toggleDailyDatePicker={toggleDatePicker}
                      datePickerRef={datePickerRef}
                      setDate={setDate}
                      effectiveDate={true}
                      minEffectiveDate={props.minEffectiveDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-6">
              <div className="py-5 border-b border-slate-200">
                <div>
                  <h3 className="text-slate-900 text-base">Note</h3>
                  <p className="text-slate-500 mt-2">
                    Leave a note for your manager (optional)
                  </p>
                </div>
                <div className="w-full mt-3">
                  <div className="p-2 bg-slate-100 rounded-2xl">
                    <TextField
                      fullWidth
                      placeholder="eg: Starting school"
                      size="small"
                      multiline
                      rows={3}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "12px",
                          paddingY: "10px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[150px]"></div>
          </div>
          <DrawerFooterContainerSmall>
            <ButtonFilled text="Request" onClick={() => handleSubmit()} />
          </DrawerFooterContainerSmall>
        </div>
      </Drawer>
    </>
  );
}
