import React from "react";
import ShiftInputsModal from "./ShiftInputsModal";
import NoCapableRolesWarningModal from "./NoCapableRolesWarningModal";
import LoadSpinner from "../../../../utilities/LoadSpinner";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";

function UnavailableCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  // Function for opening the modal
  const handleOpen = () => setOpen(true);

  // Function for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
  };

  const [isLoading, setIsLoading] = React.useState(false);

  // Default shift info
  const shift_info = {
    adjustment_type: "no_adjust",
    break_length: 0,
    break_start: 12,
    date: props.date_str,
    hours: 4,
    id: 0,
    person: props.p.scheduling_app_user_id,
    role_id: props.p.main_role_id,
    role_type: props.p.role_type,
    station_id: null,
    station: null,
    shift_end: 16,
    shift_length: 4,
    shift_start: 12,
    store: props.store_id,
    store_team_member: props.p.id,
    note: null,
  };

  // Logic for determining the break in the New Shift component
  // Assuming availability_info is an array that may be empty or contain objects without defined properties
  const availableFrom =
    (props.availability_info && props.availability_info["available_from"]) || 0;
  const availableTo =
    (props.availability_info && props.availability_info["available_to"]) || 24;
  const availableTimeSpan = availableTo - availableFrom;

  let shiftStart = availableFrom;
  let shiftEnd = availableTo;

  if (availableTimeSpan > 8) {
    shiftEnd = shiftStart + 8;
  }

  let breakLength = 0;
  let breakStart = shiftStart;

  // Only set a break if the available time span is more than the threshold
  if (availableTimeSpan >= 6) {
    // Decide on the break length based on the available time span
    breakLength = availableTimeSpan > 6 ? 0.5 : 0.5;

    // Calculate the break start time based on the middle of the shift
    breakStart =
      Math.round(((shiftEnd + shiftStart) / 2) * 4) / 4 - breakLength / 2;
  }

  let dynamicShiftInfo = shift_info;
  if (availableFrom !== 0 || availableTo !== 24) {
    // Construct the dynamic shift object
    dynamicShiftInfo = {
      ...shift_info, // Keep other default shift_info properties
      shift_start: shiftStart,
      shift_end: shiftEnd,
      shift_length: shiftEnd - shiftStart,
      break_length: breakLength,
      break_start: breakStart,
      hours: shiftEnd - shiftStart - breakLength,
      // Other properties (id, person, role_id, role_type, store) should be derived from props as before
    };
  }

  const [openNoCapableRolesWarning, setOpenNoCapableRolesWarning] =
    React.useState(false);
  const handleOpenNoCapableRolesWarning = () => {
    setOpenNoCapableRolesWarning(true);
  };
  const handleCloseNoCapableRolesWarning = () => {
    setOpenNoCapableRolesWarning(false);
  };

  // Handle right-click context menu
  const handleContextMenu = (event) => {
    props.setCopiedShift(null);
    event.preventDefault();
  };

  // Handle paste button click
  const handlePaste = (copiedShift) => {
    let new_shift = { ...copiedShift };
    if (props.p.primary_role_id) {
      if (new_shift["role_id"] !== props.p.primary_role_id) {
        new_shift["role_id"] = props.p.primary_role_id;
        new_shift["role_type"] = props.p.primary_role_type_name;
        new_shift["station"] = null;
        new_shift["station_id"] = null;
      }
    }
    new_shift["person"] = props.p.scheduling_app_user_id;
    new_shift["store_team_member"] = props.p.id;
    new_shift["store"] = props.store_id;
    new_shift["date"] = props.date_str;
    let appliedTo = [false, false, false, false, false, false, false];
    appliedTo[props.d] = true;
    setIsLoading(true);
    props.addShift(new_shift, "add", appliedTo);
    // props.setCopiedShift(null);
  };

  // Handle cut and paste
  const handleCutPaste = (copiedShift) => {
    let new_shift = { ...copiedShift };
    if (props.p.primary_role_id) {
      if (new_shift["role_id"] !== props.p.primary_role_id) {
        new_shift["role_id"] = props.p.primary_role_id;
        new_shift["role_type"] = props.p.primary_role_type_name;
        new_shift["station"] = null;
        new_shift["station_id"] = null;
      }
    }
    new_shift["person"] = props.p.scheduling_app_user_id;
    new_shift["store_team_member"] = props.p.id;
    new_shift["store"] = props.store_id;
    new_shift["date"] = props.date_str;
    new_shift["action"] = "add";

    copiedShift["action"] = "delete";
    setIsLoading(true);
    props.addMultipleShifts([copiedShift, new_shift]);
    props.setCopiedShift(null);
  };

  // Handle drop event when shift is dragged and dropped
  const handleDrop = (e, copiedShift) => {
    e.target.classList.remove("border");
    e.target.classList.remove("border-orange-500");
    handleCutPaste(copiedShift);
  };

  // Handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    const parentElement = e.currentTarget;
    parentElement.classList.remove("opacity-0");
    parentElement.classList.remove("border-2");
    parentElement.classList.add("border-2");
    parentElement.classList.add("border-orange-500");
  };

  // Handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    const parentElement = e.currentTarget;
    if (props.weekly_or_daily === "daily") {
      parentElement.classList.add("opacity-0");
    }
    parentElement.classList.remove("border-2");
    parentElement.classList.remove("border-orange-500");
  };

  React.useEffect(() => {
    if (props.saveShiftFinished) {
      setIsLoading(false);
    }
  }, [props.saveShiftFinished]);

  return (
    <>
      {isLoading ?
        <div className="w-full h-full relative">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <LoadSpinner />
          </div>
        </div> :
        <div
          className="w-full h-full cursor-pointer rounded-lg"
          onClick={
            props.copiedShift
              ? props.copiedShift["copy_type"] === "cut"
                ? () => handleCutPaste(props.copiedShift)
                : () => handlePaste(props.copiedShift)
              : props.hasNoCapableRoles
              ? handleOpenNoCapableRolesWarning
              : handleOpen
          }
          onContextMenu={(event) => handleContextMenu(event)}
          onDrop={(e) => handleDrop(e, props.copiedShift)}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <div className="w-full h-full flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md">
            <div className="w-full h-full flex justify-center items-center py-3 px-2 bg-slate-200">
              {props.copiedShift ?
                <div className="w-full h-full flex flex-col items-center justify-center text-slate-400">
                  {!props.minimizeMode &&
                    <ContentPasteGoIcon
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        border: "none",
                      }}
                    />
                  }
                  <p className={`text-slate-400 text-xxs mt-1`}>Paste shift</p>
                </div> :
                <div className="mx-1 text-slate-400 text-xs">
                  <p>No availability</p>
                </div>
              }
            </div>
          </div>
        </div>
      }
      <ShiftInputsModal
        open={open}
        handleClose={handleClose}
        shift_info={dynamicShiftInfo}
        shifts_info={[dynamicShiftInfo]}
        p={props.p}
        new_shift={true}
        addShift={props.addShift}
        date_name={props.date_name}
        get_t_str={props.get_t_str}
        availability_info={props.availability_info}
        role_names={props.role_names}
        saveShiftFinished={props.saveShiftFinished}
        setSaveShiftFinished={props.setSaveShiftFinished}
        d={props.d}
        role_types={props.role_types}
        addMultipleShifts={props.addMultipleShifts}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        frequentShifts={props.frequentShifts}
        breakWaiverEnabled={props.breakWaiverEnabled}
        defaultBreakLengthHours={props.defaultBreakLengthHours}
        breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
      />
      <NoCapableRolesWarningModal
        open={openNoCapableRolesWarning}
        handleClose={handleCloseNoCapableRolesWarning}
        p={props.p}
        role_names={props.role_names}
        updatePersonInfo={props.updatePersonInfo}
      />
    </>
  );
}

export default UnavailableCard;
