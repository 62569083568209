import React, { useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SkillRating from "./SkillRating";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RolesCapabilitySelector(props) {
  const roleIds = props.roleIds;

  useEffect(() => {
    if (roleIds.length === 1) {
      props.setMainRoleId(roleIds[0]);
    }
  }, [roleIds, props.setMainRoleId]); // Add dependencies here to ensure this effect runs only when necessary

  const getRoleLabel = (role_id) => {
    const labels = props.role_names.filter((r) => r.id === role_id);
    if (labels.length > 0) {
      return labels[0]["role_name"];
    } else {
      return "";
    }
  };

  const sortedRoles = [...props.role_names].sort((a, b) => {
    if (a.role_type_id === b.role_type_id) {
      return a.role_name.localeCompare(b.role_name);
    }
    return a.role_type_id - b.role_type_id;
  });

  const sortedRoleIds = [...roleIds].sort((a, b) => {
    const roleA = props.role_names.find((r) => r.id === a);
    const roleB = props.role_names.find((r) => r.id === b);
    if (roleA.role_type_id === roleB.role_type_id) {
      return roleA.role_name.localeCompare(roleB.role_name);
    }
    return roleA.role_type_id - roleB.role_type_id;
  });

  return (
    <div>
      <p className="text-sm text-slate-500">Capable Roles</p>
      <div className="mt-1">
        <FormControl
          fullWidth
          size="small"
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={roleIds}
            onChange={props.handleRoleChange}
            // input={<OutlinedInput label="Capable Roles" />}
            renderValue={(selected) =>
              selected
                .map((s) => {
                  return props.role_names.filter((r) => r.id === s)[0][
                    "role_name"
                  ];
                })
                .join(", ")
            }
            MenuProps={MenuProps}
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "5px",
              height: "45px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#6d28d9",
              },
            }}
          >
            <MenuItem value="" disabled>
              <ListItemText primary="Select capable roles" />
            </MenuItem>
            {sortedRoles.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                <Checkbox
                  checked={roleIds.indexOf(r.id) > -1}
                  sx={{
                    color: "#6d28d9",
                    "&.Mui-checked": {
                      color: "#6d28d9",
                    },
                  }}
                />
                <ListItemText primary={r.role_name} />
              </MenuItem>
            ))}
          </Select>
          {roleIds.length > 0 && (
            <div>
              <div className="w-full mt-4 bg-slate-50 rounded-xl pt-4 pb-3 px-4">
                <div className="w-full flex items-center">
                  <div className="w-[35%]">
                    <p className="text-xs text-slate-500">Role</p>
                  </div>
                  <div className="flex-1">
                    <p className="text-xs text-slate-500">Skill Level</p>
                  </div>
                  <div>
                    <p className="text-xs text-slate-500">Main Role</p>
                  </div>
                </div>
                <div className="mt-4">
                  {sortedRoleIds.map((selectedRole, index) => (
                    <div
                      key={index}
                      className={`w-full flex items-center py-2 ${
                        index === roleIds.length - 1
                          ? ""
                          : "border-b border-slate-200"
                      }`}
                    >
                      <div className="w-[35%]">
                        <p className="text-sm text-slate-900">
                          {getRoleLabel(selectedRole)}
                        </p>
                      </div>
                      <div className="flex-1">
                        <div className="text-xs text-slate-500">
                          <SkillRating
                            stars={props.getStars(selectedRole)}
                            update_role_proficiency={
                              props.update_role_proficiency
                            }
                            roleId={selectedRole}
                            proficiencyStarsCount={props.proficiencyStarsCount}
                          />
                        </div>
                      </div>
                      <div>
                        {selectedRole === props.mainRoleId ? (
                          <div
                            className="w-5 h-5 rounded-full bg-emerald-600 flex items-center justify-center text-white cursor-pointer"
                            onClick={() => props.setMainRoleId(selectedRole)}
                          >
                            <CheckIcon style={{ fontSize: "18px" }} />
                          </div>
                        ) : (
                          <div
                            className="w-5 h-5 rounded-full hover:bg-slate-200 border border-slate-300 flex items-center justify-center cursor-pointer"
                            onClick={() => props.setMainRoleId(selectedRole)}
                          ></div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </FormControl>
      </div>
    </div>
  );
}
