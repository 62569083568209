import React, { useState, useEffect, Component } from "react";
import * as actions from "./store/actions/auth";
import { connect } from "react-redux";

import logo from "./logo.svg";
import "./App.css";
import MainDisplay from "./main/MainDisplay";
import axios from "axios";
import { API_get_member_stores, API_change_home_store } from "./constants";
import Login from "./Login";
import LoadSpinner from "./utilities/LoadSpinner";
import OnboardingDisplay from "./main/content/onboarding/OnboardingDisplay";
import { getDefaultAxiosConfig } from "./utilities/requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let axiosConfig = getDefaultAxiosConfig();

function App(props) {
  const [storeOptions, setStoreOptions] = React.useState([]);
  const [homeStore, setHomeStore] = React.useState();
  const [homeCompany, setHomeCompany] = React.useState();
  const [storeNames, setStoreNames] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [permissionRole, setPermissionRole] = React.useState();
  const [storeInfoList, setStoreInfoList] = React.useState();

  const [isOnboarding, setIsOnboarding] = React.useState(false);

  useEffect(() => {
    props.onTryAutoSignup();
  }, []);

  const url = window.location.href;
  const urlParts = url.split("/");
  const uid = urlParts[urlParts.length - 2];
  const token = urlParts[urlParts.length - 1];

  function get_permission_role(store_id, store_list) {
    let membership = store_list.filter((v, i) => v.store === store_id);
    if (membership.length > 0) {
      return membership[0]["permission_type"]; // ["company_employee","company_manager", "company_admin", "rightwork_internal"];
    } else {
      return "company_employee";
    }
  }

  function get_member_stores(username) {
    setIsLoading(true);
    if (props.isAuthenticated) {
      axiosConfig = getDefaultAxiosConfig();
      axios.get(API_get_member_stores + username, axiosConfig).then((res) => {
        const store_id = res.data["user_info"]["home_store"];
        const company_id = res.data["user_info"]["home_company"];
        console.log("STORE INFO", res.data["membership_info"]);
        setStoreOptions(res.data["membership_info"]);
        setHomeStore(store_id);
        setHomeCompany(company_id);

        setStoreNames(res.data["store_info"]);
        setPermissionRole(
          get_permission_role(store_id, res.data["membership_info"])
        );
        setStoreInfoList(res.data["store_info"]);
        setIsLoading(false);
      });
    }
  }

  function change_home_store(new_home_store) {
    axios
      .put(
        API_change_home_store + props.username + "&new_store=" + new_home_store,
        {},
        axiosConfig
      )
      .then(() => {
        setHomeStore(new_home_store);
        setPermissionRole(get_permission_role(new_home_store, storeOptions));
      });
  }

  useEffect(() => {
    get_member_stores(props.username);
  }, [props.isAuthenticated]);

  //console.log(storeOptions);
  //console.log(homeStore);
  //console.log(storeNames);

  const homeStoreInfo = storeInfoList?.filter((item) => item.id === homeStore);
  const timeEntriesEnabled = homeStoreInfo?.[0]?.time_entries_enabled;
  const trainingEnabled = homeStoreInfo?.[0]?.training_enabled;
  const breakWaiverEnabled = homeStoreInfo?.[0]?.break_waiver_enabled;
  const chatEnabled = homeStoreInfo?.[0]?.chat_enabled;
  const employeeCreateChatEnabled =
    homeStoreInfo?.[0]?.employee_create_chat_enabled;
  const managerCreateChatEnabled =
    homeStoreInfo?.[0]?.manager_create_chat_enabled;
  const laborPercentEnabled = homeStoreInfo?.[0]?.labor_percent_enabled;
  const logbookEnabled = homeStoreInfo?.[0]?.logbook_enabled;
  const tplhEnabled = homeStoreInfo?.[0]?.tplh_enabled;
  const editShiftsInPastEnabled =
    homeStoreInfo?.[0]?.edit_shifts_in_past_enabled;
  const dailyPlanEnabled = homeStoreInfo?.[0]?.daily_plan_enabled;
  const defaultBreakLengthHours =
    homeStoreInfo?.[0]?.default_break_length_hours;
  const companyId = homeStoreInfo?.[0]?.company;
  const copilotEnabled = homeStoreInfo?.[0]?.copilot_enabled;
  const defaultDailyCardChartEnabled =
    homeStoreInfo?.[0]?.default_daily_card_chart_enabled;
  const minimizedViewDefaultEnabled =
    homeStoreInfo?.[0]?.minimized_view_default_enabled;
  const breakRequiredWhenShiftLengthExceeds =
    homeStoreInfo?.[0]?.break_required_when_shift_length_exceeds;
  const proficiencyStarsCount =
    homeStoreInfo?.[0]?.company_info?.proficiency_stars_count;
  const requestMinimumDaysInAdvance = homeStoreInfo?.[0]?.request_minimum_days_in_advance

  return (
    <div className="App">
      {props.isAuthenticated ? (
        isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <LoadSpinner />
          </div>
        ) : (
          <MainDisplay
            storeOptions={storeOptions}
            homeStore={homeStore}
            homeCompany={companyId}
            storeNames={storeNames}
            change_home_store={change_home_store}
            username={props.username}
            permissionRole={permissionRole}
            timeEntriesEnabled={timeEntriesEnabled}
            trainingEnabled={trainingEnabled}
            chatEnabled={chatEnabled}
            breakWaiverEnabled={breakWaiverEnabled}
            laborPercentEnabled={laborPercentEnabled}
            logbookEnabled={logbookEnabled}
            defaultBreakLengthHours={defaultBreakLengthHours}
            tplhEnabled={tplhEnabled}
            managerCreateChatEnabled={managerCreateChatEnabled}
            employeeCreateChatEnabled={employeeCreateChatEnabled}
            editShiftsInPastEnabled={editShiftsInPastEnabled}
            dailyPlanEnabled={dailyPlanEnabled}
            copilotEnabled={copilotEnabled}
            defaultDailyCardChartEnabled={defaultDailyCardChartEnabled}
            minimizedViewDefaultEnabled={minimizedViewDefaultEnabled}
            breakRequiredWhenShiftLengthExceeds={breakRequiredWhenShiftLengthExceeds}
            proficiencyStarsCount={proficiencyStarsCount}
            requestMinimumDaysInAdvance={requestMinimumDaysInAdvance}
          />
        )
      ) : isOnboarding ? (
        <OnboardingDisplay />
      ) : (
        <Login uid={uid} token={token} />
      )}
      <ToastContainer position="bottom-left" newestOnTop autoClose={3000} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.token !== null,
    username: state.user,
    email: state.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
