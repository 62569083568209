import React from "react";
import { PurpleSwitch } from "../../../helpers/helpers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { generateTimeOptions } from "../../../helpers/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { get_t_str } from "../../../helpers/helpers";
import { text_to_num } from "../../../helpers/helpers";
import { formatDateReadableWithDayOfWeek } from "../requests/utilities/helpers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

function SpecialHoursOfOperationsInput(props) {
  const [isDayOff, setIsDayOff] = React.useState(
    props.day.open_time === 0 && props.day.close_time === 0
  );

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;

    // Update isDayOff state
    setIsDayOff(isChecked);

    // If the switch is checked, set open_time and close_time to 0
    if (isChecked) {
      const updatedSpecialHours = [...props.hoursOfOperationData.specialHours];
      const updatedDay = {
        ...updatedSpecialHours[props.index],
        open_time: 0,
        close_time: 0,
      };
      updatedSpecialHours[props.index] = updatedDay;
      props.setHoursOfOperationsData({
        ...props.hoursOfOperationData,
        specialHours: updatedSpecialHours,
      });
    }
  };

  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]); // Re-run the effect when 'showDailyDatePicker' changes

  const handleDeleteClick = () => {
    props.onDelete();
  };

  const handleChange = (evt, index, field) => {
    const value = evt.target.value;
    const updatedSpecialHours = [...props.hoursOfOperationData.specialHours];
    const updatedDay = {
      ...updatedSpecialHours[index],
      [field]: text_to_num(value),
    };
    updatedSpecialHours[index] = updatedDay;
    props.setHoursOfOperationsData({
      ...props.hoursOfOperationData,
      specialHours: updatedSpecialHours,
    });
  };

  const [dateValue, setDateValue] = React.useState(dayjs(props.day.date));

  const handleDateChange = (newValue, index) => {
    const updatedSpecialHours = [...props.hoursOfOperationData.specialHours];
    updatedSpecialHours[index].date = newValue.format("YYYY-MM-DD");

    // Update dateValue with the new date value
    setDateValue(newValue);

    props.setHoursOfOperationsData({
      ...props.hoursOfOperationData,
      specialHours: updatedSpecialHours,
    });
  };

  return (
    <div className="w-full bg-slate-50 rounded-xl p-4">
      <div className="w-full flex items-center gap-x-2">
        <div
          className={`${
            !showDailyDatePicker ? "border border-white" : ""
          } flex-1`}
        >
          <div
            className={`flex-1 bg-white rounded-lg text-sm ${
              showDailyDatePicker
                ? "border-2 border-violet-700"
                : "border border-[#bdbdbd] hover:border-slate-900"
            } cursor-pointer relative`}
            ref={datePickerRef}
          >
            <div
              className="w-full h-full px-4 py-2"
              onClick={toggleDailyDatePicker}
            >
              <div className="w-full h-full flex items-center justify-between">
                <p>{formatDateReadableWithDayOfWeek(props.day.date)}</p>
                <div className="text-slate-500">
                  <CalendarTodayIcon
                    style={{ fontSize: "18px", marginBottom: "2px" }}
                  />
                </div>
              </div>
            </div>
            {showDailyDatePicker && (
              <div className="absolute bottom-10 left-0 bg-white z-10 flex items-center justify-center pl-4 pr-5 border border-[#bdbdbd] rounded-lg shadow-xl overflow-hidden">
                <div className="mt-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DateCalendar", "DateCalendar"]}
                    >
                      <DemoItem>
                        <DateCalendar
                          value={dateValue}
                          onChange={(newValue) =>
                            handleDateChange(newValue, props.index)
                          }
                          slotProps={{
                            day: {
                              sx: {
                                "&.MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#6d28d9",
                                },
                              },
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div
            className="w-10 h-10 bg-slate-100 hover:bg-slate-200 flex items-center justify-center rounded-md text-rose-800 cursor-pointer"
            onClick={handleDeleteClick}
          >
            <DeleteOutlineOutlinedIcon />
          </div>
        </div>
      </div>
      {!isDayOff && (
        <div className="mt-3">
          <div className="flex items-center gap-x-2">
            <div className="flex-1">
              <Select
                fullWidth
                value={get_t_str(props.day.open_time)}
                name="open_time"
                onChange={(evt) => handleChange(evt, props.index, "open_time")}
                size="small"
                sx={{
                  borderRadius: "8px",
                  paddingY: "1px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6d28d9",
                  },
                  fontSize: "14px",
                  backgroundColor: "white",
                }}
              >
                {generateTimeOptions(props.maxStartQhnum).map(
                  (option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
            <div>
              <div className="text-slate-500 flex items-center justify-center">
                <ArrowForwardIcon style={{ fontSize: "20px" }} />
              </div>
            </div>
            <div className="flex-1">
              <Select
                fullWidth
                value={get_t_str(props.day.close_time)}
                name="close_time"
                onChange={(evt) => handleChange(evt, props.index, "close_time")}
                size="small"
                sx={{
                  borderRadius: "8px",
                  paddingY: "1px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6d28d9",
                  },
                  fontSize: "14px",
                  backgroundColor: "white",
                }}
              >
                {generateTimeOptions(props.maxStartQhnum).map(
                  (option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3">
        <div className="flex items-center gap-x-1">
          <PurpleSwitch
            size="small"
            checked={isDayOff}
            onChange={handleSwitchChange}
          />
          <p className="text-slate-900 text-sm">Mark as day off</p>
        </div>
      </div>
    </div>
  );
}

export default SpecialHoursOfOperationsInput;
