import React, { useEffect } from "react";
import ShiftInputsModal from "./ShiftInputsModal";
import ShiftRecapModal from "./ShiftRecapModal";
import WarningIcon from "@mui/icons-material/Warning";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import LoadSpinner from "../../../../utilities/LoadSpinner";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getCardColor,
  getCardColor2,
  getRoleType,
  get_t_str,
  get_t_str_shortened,
} from "../../../../helpers/helpers";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import useLongPress from "../../../../helpers/useLongPress";

function ShiftCard(props) {
  // Opens the modal when true
  const [open, setOpen] = React.useState(false);

  // Shows the copy button for copy and paste when true
  const [showActionButtons, setShowActionButtons] = React.useState(false);

  React.useEffect(() => {
    setShowActionButtons(props.shift_info?.id === props.copiedShift?.id);
  }, [props.shift_info]);

  React.useEffect(() => {
    if (props.setShowBreak && !props.shift_info?.is_copilot_suggestion) {
      props.setShowBreak(!showActionButtons);
    }
  }, [showActionButtons]);

  //const [showPasteButton, setShowPasteButton] = React.useState(false);

  // Shows the check when they have successfully copied a shift when true
  const [showCopiedCheck, setShowCopyCheck] = React.useState(false);

  // Shows the check when they have successfully cut a shift when true
  const [showCutCheck, setShowCutCheck] = React.useState(false);

  // When dragging over with copied shift
  const [isDraggedOver, setIsDraggedOver] = React.useState(false);

  // Loading state for transferring shifts
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (props.saveShiftFinished) {
      setIsLoading(false);
    }
  }, [props.saveShiftFinished]);

  // Function that handles the opening of the modal
  const handleOpen = () => {
    setOpen(!props.locked_mode);
  };

  // Function that handles the closing of the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
    setShowActionButtons(false);
  };

  // Function that handles the context menu
  const handleContextMenu = (event) => {
    event.preventDefault();
    if (showActionButtons) {
      props.setCopiedShift(null);
      setShowActionButtons(false);
    } else {
      setShowActionButtons((prevState) => !prevState);
    }
  };

  // Function that handles the copying of a shift
  const handleCopy = (shift) => {
    if (!showCopiedCheck) {
      // Create a copy of the shift object and set the copy_type to "copy"
      const updatedShift = { ...shift, copy_type: "copy" };
      props.setCopiedShift(updatedShift); // Set the copied shift state
      setShowCopyCheck(true);
      setShowCutCheck(false);
    } else {
      props.setCopiedShift(null); // Reset the copied shift state
      setShowCopyCheck(false);
      setShowCutCheck(false);
    }
  };

  // Function that handles the cutting of a shift
  const handleCut = (shift) => {
    if (!showCutCheck) {
      // Create a copy of the shift object and set the copy_type to "cut"
      const updatedShift = { ...shift, copy_type: "cut" };
      props.setCopiedShift(updatedShift); // Set the copied shift state
      setShowCutCheck(true);
      setShowCopyCheck(false);
    } else {
      props.setCopiedShift(null); // Reset the copied shift state
      setShowCutCheck(false);
      setShowCopyCheck(false);
    }
  };

  // Hides the copy button and copy check when props.copiedShift changes
  useEffect(() => {
    if (props.copiedShift && props.copiedShift.id !== props.shift_info.id) {
      // Compare the shift IDs instead of the entire object
      setShowActionButtons(false);
      setShowCopyCheck(false);
      setShowCutCheck(false);
    }
  }, [props.copiedShift, props.shift_info]);

  // Function that gets the role name
  const getRoleName = (role_id, role_lookups) => {
    const role_info = role_lookups.filter((r) => r.id === role_id);
    if (role_info.length > 0) {
      return role_info[0]["role_name"];
    } else {
      return null;
    }
  };

  // const shiftCardColor = getCardColor(
  //   props.shift_info.role_id,
  //   props.role_names,
  //   props.role_types,
  //   props.locked_mode
  // );

  const shiftCardColor = getCardColor2(
    props.shift_info.role_id,
    props.role_names,
    props.locked_mode,
    true
  );

  // Function  that handles the drag start when dragging and dropping a shift
  const handleDragStart = (e, shift) => {
    if (shift?.is_copilot_suggestion) {
      e.preventDefault();
      return;
    }
    //e.dataTransfer.setData("shift", JSON.stringify(shift));
    props.setCopiedShift(shift);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // This is necessary to allow dropping
    setIsDraggedOver(true);
  };

  const handleDragLeave = (e) => {
    // Prevent the event from being handled by parent elements
    e.preventDefault();
    e.stopPropagation();

    // Only reset isDraggedOver if we're not entering a child element
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDraggedOver(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const draggedShift = props.copiedShift;
    const targetShift = props.shift_info;

    if (draggedShift.id === targetShift.id) {
      setIsDraggedOver(false);
      return; // Do nothing if dropping on the same shift
    }

    // Swap the relevant information
    const newDraggedShift = { ...draggedShift };
    const newTargetShift = { ...targetShift };

    // Swap person and store_team_member
    [newDraggedShift.person, newTargetShift.person] = [
      newTargetShift.person,
      newDraggedShift.person,
    ];
    [newDraggedShift.store_team_member, newTargetShift.store_team_member] = [
      newTargetShift.store_team_member,
      newDraggedShift.store_team_member,
    ];
    [newDraggedShift.date, newTargetShift.date] = [
      newTargetShift.date,
      newDraggedShift.date,
    ];
    [newDraggedShift.store, newTargetShift.store] = [
      newTargetShift.store,
      newDraggedShift.store,
    ];

    [newDraggedShift.role_type, newTargetShift.role_type] = [
      newTargetShift.role_type,
      newDraggedShift.role_type,
    ];

    [newDraggedShift.role_id, newTargetShift.role_id] = [
      newTargetShift.role_id,
      newDraggedShift.role_id,
    ];

    newDraggedShift.action = "add";
    newTargetShift.action = "add";
    draggedShift.action = "delete";
    targetShift.action = "delete";

    setIsLoading(true);

    // Call the addMultipleShifts function to update both shifts
    props.addMultipleShifts([
      newDraggedShift,
      newTargetShift,
      draggedShift,
      targetShift,
    ]);

    // Clear the copiedShift
    props.setCopiedShift(null);

    setIsDraggedOver(false);
  };

  // Handles opening up shift recap modal

  const [openRecapModal, setOpenRecapModal] = React.useState(false);
  const handleOpenRecapModal = () => {
    // console.log("Would open recap modal");
    setOpenRecapModal(props.locked_mode);
  };
  const handleCloseRecapModal = () => {
    setOpenRecapModal(false);
  };

  // Copilot logic

  const [appliedTo, setAppliedTo] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  React.useEffect(() => {
    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
  }, []);

  const [isCopilotSuggestion, setIsCopilotSuggestion] = React.useState(
    props.shift_info?.is_copilot_suggestion
      ? props.shift_info?.is_copilot_suggestion
      : false
  );

  React.useEffect(() => {
    setIsCopilotSuggestion(
      props.shift_info?.is_copilot_suggestion
        ? props.shift_info?.is_copilot_suggestion
        : false
    );
  }, [props.shift_info]);

  const onLongPressShift = () => {
    if (isCopilotSuggestion) {
      setIsCopilotSuggestion(false);
      props.addShift(props.shift_info, "add", appliedTo, true);
    }
  };

  const onClickShift = () => {
    if (!props.day_in_past) {
      handleOpen();
    } else if (props.editShiftsInPastEnabled) {
      setOpen(true);
    } else {
      handleOpenRecapModal();
    }
  };

  const deleteShift = () => {
    setIsLoading(true);
    props.addShift(props.shift_info, "delete", appliedTo, false);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    onLongPressShift,
    onClickShift,
    defaultOptions
  );

  return (
    <>
      <style>
        {`
          @keyframes wiggle {
            0%, 100% {
              transform: rotate(-2deg);
            }
            50% {
              transform: rotate(2deg);
            }
          }
          .wiggle {
            animation: wiggle 0.3s ease-in-out infinite;
          }
        `}
      </style>
      <div className="w-full h-full relative">
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <LoadSpinner />
          </div>
        ) : (
          <div className="w-full h-full relative">
            {props.isCopilotOn &&
              props.shift_info?.copilot_suggested_deletion && (
                <Tooltip title="Copilot recommends deleting this shift - click the X to delete">
                  <div
                    className="absolute -left-2 -top-2 w-5 h-5 rounded-full bg-slate-900 text-white hover:bg-slate-200 hover:text-slate-900 flex items-center justify-center z-10 cursor-pointer"
                    onClick={deleteShift}
                  >
                    <CloseIcon style={{ fontSize: "18px" }} />
                  </div>
                </Tooltip>
              )}
            {!showActionButtons || props.shift_info?.is_copilot_suggestion ? (
              <div
                {...(props.shift_info?.is_copilot_suggestion
                  ? longPressEvent
                  : { onClick: onClickShift })}
                className={`w-full h-full flex overflow-hidden ${
                  isDraggedOver ? "border-2 border-emerald-500" : ""
                } bg-white rounded-lg ${
                  props.showUnpublishedShifts &&
                  props.shift_info?.is_publishable
                    ? "wiggle"
                    : ""
                } shadow-md ${
                  props.weekly_or_daily === "weekly" ? "max-w-sm" : ""
                } ${
                  props.shift_info?.publishedschedule?.missed_shift_reason
                    ? "border-2 border-rose-500"
                    : ""
                }`}
                onContextMenu={(event) => handleContextMenu(event)}
                draggable={
                  !props.locked_mode && !props.shift_info?.is_copilot_suggestion
                }
                onDragStart={(e) => handleDragStart(e, props.shift_info)}
                onDragEnd={(e) => props.setCopiedShift(null)}
                onDragOver={
                  !props.shift_info?.is_copilot_suggestion
                    ? handleDragOver
                    : null
                }
                onDragLeave={
                  !props.shift_info?.is_copilot_suggestion
                    ? handleDragLeave
                    : null
                }
                onDrop={
                  !props.shift_info?.is_copilot_suggestion ? handleDrop : null
                }
              >
                <div
                  className={`w-full h-full flex items-center py-3 px-2 text-white relative ${shiftCardColor} ${
                    isCopilotSuggestion ? "opacity-40" : "opacity-100"
                  }`}
                >
                  <div className="w-full mx-1">
                    {props.weekly_or_daily === "weekly" ? (
                      <div>
                        <p className="text-xs font-bold xl:font-normal">
                          {get_t_str_shortened(props.shift_info.shift_start) +
                            " - " +
                            get_t_str_shortened(props.shift_info.shift_end)}
                        </p>
                        {props.shifts_info?.length === 1 ? (
                          <div>
                            {!props.minimizeMode &&
                              (props.shift_info.station ? (
                                <p className="text-xxs xl:hidden">
                                  {props.shift_info.station.name}
                                </p>
                              ) : getRoleName(
                                  props.shift_info.role_id,
                                  props.role_names
                                ) ? (
                                <p className="text-xxs xl:hidden">
                                  {getRoleName(
                                    props.shift_info.role_id,
                                    props.role_names
                                  )}
                                </p>
                              ) : (
                                <p className="text-xxs xl:hidden">
                                  {props.shift_info.role_type}
                                </p>
                              ))}
                          </div>
                        ) : (
                          <div>
                            {!props.minimizeMode && (
                              <p className="text-xs font-bold xl:font-normal xl:hidden">
                                {get_t_str_shortened(
                                  props.shifts_info?.[1]?.shift_start
                                ) +
                                  " - " +
                                  get_t_str_shortened(
                                    props.shifts_info?.[1]?.shift_end
                                  )}
                              </p>
                            )}
                            {!props.minimizeMode &&
                              props.shifts_info?.length === 3 && (
                                <p className="text-xs font-bold xl:font-normal xl:hidden">
                                  {get_t_str_shortened(
                                    props.shifts_info?.[2]?.shift_start
                                  ) +
                                    " - " +
                                    get_t_str_shortened(
                                      props.shifts_info?.[2]?.shift_end
                                    )}
                                </p>
                              )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="h-full flex items-center gap-4 pl-3">
                        <p className="text-xs font-bold xl:font-normal">
                          {get_t_str_shortened(props.shift_info.shift_start) +
                            " - " +
                            get_t_str_shortened(props.shift_info.shift_end)}
                        </p>
                        {props.shift_info.station ? (
                          <p className="text-xs xl:hidden">
                            {props.shift_info?.station.name}
                          </p>
                        ) : getRoleName(
                            props.shift_info.role_id,
                            props.role_names
                          ) ? (
                          <p className="text-xs xl:hidden">
                            {getRoleName(
                              props.shift_info.role_id,
                              props.role_names
                            )}
                          </p>
                        ) : (
                          <p className="text-xs xl:hidden">
                            {props.shift_info.role_type}
                          </p>
                        )}
                      </div>
                    )}
                    {(!props.availability_info ||
                    (props.availability_info.available_from === 0 &&
                      props.availability_info.available_to === 24 &&
                      !props.time_off_conflict)
                      ? false
                      : ((props.availability_info.available_from >
                          props.shift_info.shift_start ||
                          props.availability_info.available_to <
                            props.shift_info.shift_end) &&
                          (props.availability_info.available_from_2 >
                            props.shift_info.shift_start ||
                            props.availability_info.available_to_2 <
                              props.shift_info.shift_end)) ||
                        props.time_off_conflict) && (
                      <div
                        className={`absolute ${
                          props.minimizeMode
                            ? props.weekly_or_daily === "weekly"
                              ? "right-1 bottom-0"
                              : "right-2 bottom-0.5"
                            : props.weekly_or_daily === "weekly"
                            ? "right-2 bottom-1"
                            : "right-2 bottom-0.5"
                        }`}
                      >
                        <Tooltip
                          title={
                            props.time_off_conflict
                              ? "Time off requested for this day"
                              : "Shift outside of availability"
                          }
                        >
                          <WarningIcon
                            sx={{
                              fontSize: props.minimizeMode ? "8px" : "10px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                    {props.shift_info?.note && (
                      <div
                        className={`absolute ${
                          props.minimizeMode
                            ? props.weekly_or_daily === "weekly"
                              ? "right-1 -top-1.5"
                              : "right-2 -top-1.5"
                            : props.weekly_or_daily === "weekly"
                            ? "right-2 -top-0.5"
                            : "right-2 -top-1.5"
                        }`}
                      >
                        <Tooltip
                          title={
                            (() => {
                              // Collect all non-empty notes
                              const notes = [
                                props.shift_info?.note,
                                props.shifts_info?.[1]?.note,
                                props.shifts_info?.[2]?.note
                              ].filter(note => note);

                              // If multiple notes, create a bulleted list
                              if (notes.length > 1) {
                                return (
                                  <ul>
                                    {notes.map((note, index) => (
                                      <li key={index}>• {note}</li>
                                    ))}
                                  </ul>
                                );
                              }

                              // If only one note, return it directly
                              return notes[0];
                            })()
                          }
                        >
                          <NoteAltIcon
                            sx={{
                              fontSize: props.minimizeMode ? "8px" : "10px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={
                  "w-full h-full flex overflow-hidden bg-white rounded-lg shadow-md " +
                  (props.weekly_or_daily === "weekly" ? "max-w-sm" : "")
                }
                onContextMenu={(event) => handleContextMenu(event)}
              >
                <div
                  className={`w-full h-full flex items-center py-3 px-2 text-white relative ${shiftCardColor}`}
                >
                  <div className="w-full mx-1">
                    <div className="w-full flex items-center justify-between space-x-1.5">
                      <Tooltip title={"Edit or add shift"} arrow>
                        <div
                          className={`flex-1 ${
                            props.weekly_or_daily === "weekly" ? "h-8" : "h-6"
                          } bg-slate-800 opacity-50 hover:bg-slate-700 rounded flex items-center justify-center`}
                          onClick={handleOpen}
                        >
                          <EditIcon style={{ fontSize: "14px" }} />
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          !showCopiedCheck
                            ? "Copy shift"
                            : "Shift copied. When you paste this shift to another slot, the original will remain"
                        }
                        arrow
                      >
                        <div
                          className={`flex-1 ${
                            props.weekly_or_daily === "weekly" ? "h-8" : "h-6"
                          } bg-slate-800 opacity-50 hover:bg-slate-700 rounded flex items-center justify-center`}
                          onClick={() => handleCopy(props.shift_info)}
                        >
                          {!showCopiedCheck ? (
                            <ContentCopyIcon style={{ fontSize: "14px" }} />
                          ) : (
                            <CheckIcon style={{ fontSize: "20px" }} />
                          )}
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          !showCutCheck
                            ? "Cut shift"
                            : "Shift cut. When you paste this shift to another slot, the original will disappear"
                        }
                        arrow
                      >
                        <div
                          className={`flex-1 ${
                            props.weekly_or_daily === "weekly" ? "h-8" : "h-6"
                          } bg-slate-800 opacity-50 hover:bg-slate-700 rounded flex items-center justify-center`}
                          onClick={() => handleCut(props.shift_info)}
                        >
                          {!showCutCheck ? (
                            <ContentCutIcon style={{ fontSize: "14px" }} />
                          ) : (
                            <CheckIcon style={{ fontSize: "20px" }} />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!showActionButtons &&
              !showCopiedCheck &&
              props.shifts_info.length > 1 &&
              props.weekly_or_daily === "weekly" &&
              !(props.minimizeMode === false && props.shift_info.note) && (
                <div
                  className={`absolute ${
                    props.minimizeMode ? "right-3.5 top-0" : "right-2 top-0"
                  }`}
                >
                  <Tooltip
                    title={`${props.shifts_info.length} shifts today! Click the shift card to see details`}
                  >
                    {props.shifts_info.length === 2 ? (
                      <Filter2Icon
                        style={{ fontSize: "10px", color: "white" }}
                      />
                    ) : (
                      <Filter3Icon
                        style={{ fontSize: "10px", color: "white" }}
                      />
                    )}
                  </Tooltip>
                </div>
              )}
            <ShiftInputsModal
              open={open}
              handleClose={handleClose}
              shift_info={props.shift_info}
              shifts_info={props.shifts_info}
              p={props.p}
              new_shift={isCopilotSuggestion ? true : false}
              addShift={props.addShift}
              date_name={props.date_name}
              availability_info={props.availability_info}
              role_names={props.role_names}
              getRoleName={getRoleName}
              getRoleType={getRoleType}
              saveShiftFinished={props.saveShiftFinished}
              setSaveShiftFinished={props.setSaveShiftFinished}
              d={props.d}
              role_types={props.role_types}
              addMultipleShifts={props.addMultipleShifts}
              businessWeekStartDayIndex={props.businessWeekStartDayIndex}
              start_qhnum={props.start_qhnum}
              frequentShifts={props.frequentShifts}
              breakWaiverEnabled={props.breakWaiverEnabled}
              person_day_time_off_requests={props.person_day_time_off_requests}
              defaultBreakLengthHours={props.defaultBreakLengthHours}
              breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
            />
            <ShiftRecapModal
              open={openRecapModal}
              handleClose={handleCloseRecapModal}
              p={props.p}
              shift_info={props.shift_info}
              post_missed_shift={props.post_missed_shift}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ShiftCard;
