import React, { useState, useEffect, useRef } from "react";
import {
  getRoleType,
  getRoleName,
  text_to_num,
  get_t_str,
  generateTimeOptions,
  filterOptions,
} from "../../../../helpers/helpers";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import ShiftCardModalDisplay from "./ShiftCardModalDisplay";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import WarningIcon from "@mui/icons-material/Warning";
import { createFilterOptions } from "@mui/material/Autocomplete";

function ShiftSingleInputModal(props) {
  const initialShiftInfoString = {
    shift_start: get_t_str(props.shift_info.shift_start),
    shift_end: get_t_str(props.shift_info.shift_end),
  };

  const initialBreakInfoString = {
    break_start: get_t_str(props.shift_info.break_start),
    break_end: get_t_str(
      props.shift_info.break_start + props.shift_info.break_length
    ),
  };

  const initialBreakChecked = props.shift_info.break_length > 0;
  const initialNoteChecked = props.shift_info.note !== null;

  const [breakChecked, setBreakChecked] = useState(initialBreakChecked);
  const [isBreakManuallyControlled, setIsBreakManuallyControlled] = React.useState(false);

  const [noteChecked, setNoteChecked] = useState(initialNoteChecked);

  const [shiftInfoString, setShiftInfoString] = React.useState(
    initialShiftInfoString
  );

  const [breakInfoString, setBreakInfoString] = React.useState(
    initialBreakInfoString
  );

  React.useEffect(() => {
    if (props.open) {
      // Focus and select the shift-start input when the modal opens
      // Move focus directly to the Shift End input field by using its ID
      const shiftStartInput = document.getElementById("shift-start");
      if (shiftStartInput) {
        setTimeout(() => {
          shiftStartInput.focus(); // Move focus to the Shift End input field
          shiftStartInput.select();
        }, 0); // Adding a small timeout to ensure the DOM is ready
      }
    }
  }, [props.open]); // Ensure this runs when the modal is opened

  React.useEffect(() => {
    // Only update breakChecked automatically if the user hasn't manually overridden it
    if (!isBreakManuallyControlled) {
      const shiftLength = props.shift_info.shift_end - props.shift_info.shift_start;

      // Safely handle null breakRequiredWhenShiftLengthExceeds
      const breakRequired =
        props.breakRequiredWhenShiftLengthExceeds !== null
          ? shiftLength > props.breakRequiredWhenShiftLengthExceeds
          : false;


      setBreakChecked(breakRequired || initialBreakChecked);

      if (breakRequired && !initialBreakChecked) {
        let new_break_length = props.defaultBreakLengthHours;
        let new_break_start =
          Math.round(
            ((props.shift_info.shift_end + props.shift_info.shift_start) / 2) * 4
          ) /
            4 -
          new_break_length / 2;

        const updatedShift = {
          ...props.shift_info,
          break_start: new_break_start,
          break_end: new_break_start + new_break_length,
          break_length: new_break_length,
        };

        setBreakInfoString({
          break_start: get_t_str(new_break_start),
          break_end: get_t_str(new_break_start + new_break_length),
        });

        props.onUpdateShift(updatedShift);
      }

      setNoteChecked(initialNoteChecked);
      setShiftInfoString(initialShiftInfoString);
    }
  }, [props.shift_info, props.breakRequiredWhenShiftLengthExceeds]);

  const shiftData = {
    info: props.shift_info,
  };

  function handleRoleChange(e) {
    const { value, name } = e.target;
    const updatedShift = {
      ...shiftData.info,
      [name]: value,
      role_type: getRoleType(value, props.role_names),
    };
    props.onUpdateShift(updatedShift);
  }

  function handleStationChange(e) {
    const { value, name } = e.target;
    const updatedShift = {
      ...shiftData.info,
      [name]: value,
      ["station"]: generateStationDict(shiftData.info.role_id, value),
    };
    console.log(updatedShift);
    console.log(name);
    console.log(value);
    props.onUpdateShift(updatedShift);
  }

  function handleShiftInfo(e) {
    const { value, name } = e.target;
    const updatedShift = {
      ...shiftData.info,
      [name]: text_to_num(value),
    };
    setShiftInfoString({
      ...shiftInfoString,
      [name]: value,
    });
    props.onUpdateShift(updatedShift);
  }

  function handleBreakInfo(e) {
    const { value, name } = e.target;
    let updatedShift = {
      ...shiftData.info,
      [name]: text_to_num(value),
    };

    if (updatedShift.break_start >= updatedShift.break_end) {
      // Handle invalid break times
      updatedShift.break_end =
        updatedShift.break_start + props.defaultBreakLengthHours;
    }

    setBreakInfoString((prevBreakInfo) => ({
      ...prevBreakInfo,
      [name]: value,
    }));

    updatedShift["break_length"] =
      updatedShift["break_end"] - updatedShift["break_start"];

    props.onUpdateShift(updatedShift);
  }

  function handleCheckChange(e) {
    const { checked } = e.target;

    // User has manually toggled the checkbox
    setIsBreakManuallyControlled(true);

    let new_break_length = checked
      ? shiftData.info.shift_end - shiftData.info.shift_start > 6
        ? props.defaultBreakLengthHours
        : props.defaultBreakLengthHours
      : 0;
    let new_break_start =
      Math.round(
        ((shiftData.info.shift_end + shiftData.info.shift_start) / 2) * 4
      ) /
        4 -
      new_break_length / 2;

    const updatedShift = {
      ...shiftData.info,
      break_start: new_break_start,
      break_end: new_break_start + new_break_length,
      break_length: new_break_length,
    };

    setBreakInfoString({
      ...breakInfoString,
      break_start: get_t_str(new_break_start),
      break_end: get_t_str(new_break_start + new_break_length),
    });

    setBreakChecked(checked);
    props.onUpdateShift(updatedShift);
  }

  function handleNoteCheckChange(e) {
    const { checked } = e.target;
    setNoteChecked(checked);
    if (!checked) {
      const updatedShift = {
        ...shiftData.info,
        ["note"]: null,
      };
      props.onUpdateShift(updatedShift);
    }
  }

  function handleNoteChange(e) {
    const { value, name } = e.target;
    const updatedShift = {
      ...shiftData.info,
      ["note"]: value,
    };
    props.onUpdateShift(updatedShift);
  }

  function generateBreakOptions(start, end) {
    const options = [];
    let currentTime = start;

    while (currentTime <= end) {
      options.push(get_t_str(currentTime));
      currentTime += 0.25;
    }

    return options;
  }
  function generateStationOptions(selectedRoleId) {
    const selectedRoleObject = props.role_names.find(
      (role) => role.id === selectedRoleId
    );
    return selectedRoleObject
      ? selectedRoleObject.station_options.map((station) => (
          <MenuItem key={station.station_id} value={station.station_id}>
            {station.station_name}
          </MenuItem>
        ))
      : [];
  }

  function generateStationDict(selectedRoleId, selectedStationId) {
    const selectedRoleObject = props.role_names.find(
      (role) => role.id === selectedRoleId
    );
    if (selectedRoleObject.station_options) {
      let station_dict = selectedRoleObject.station_options.find(
        (station) => station.station_id === selectedStationId
      );
      if (station_dict) {
        station_dict["id"] = station_dict["station_id"];
        station_dict["name"] = station_dict["station_name"];
        return station_dict;
      }
    }
    return null;
  }

  console.log("SHIFT EXCEEDS", props.breakRequiredWhenShiftLengthExceeds)

  return (
    <div>
      <div className="flex flex-col">
        <Box sx={{ minWidth: 120 }}>
          <p className="text-sm text-slate-500">Role</p>
          <div className="mt-1">
            <FormControl
              fullWidth
              size="small"
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={shiftData.info.role_id}
                name="role_id"
                onChange={handleRoleChange}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  padding: "5px",
                  height: "45px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6d28d9",
                  },
                }}
              >
                {props.p.available_role_info.length > 0 ? (
                  props.p.available_role_info
                    .sort((a, b) => {
                      if (
                        a.scheduled_role_obj?.role_type_id ===
                        b.scheduled_role_obj?.role_type_id
                      ) {
                        return a.scheduled_role_obj?.role_name.localeCompare(
                          b.scheduled_role_obj?.role_name
                        );
                      }
                      return (
                        a.scheduled_role_obj?.role_type_id -
                        b.scheduled_role_obj?.role_type_id
                      );
                    })
                    .map((role) => (
                      <MenuItem value={role.scheduled_role}>
                        {getRoleName(role.scheduled_role, props.role_names)}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value={null}>
                    {"No role capabilities available"}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        </Box>
        <div>
          {props.p.available_role_info.length > 0 &&
            generateStationOptions(shiftData.info.role_id).length > 0 && (
              <div className="mt-4">
                <Box sx={{ minWidth: 120 }}>
                  <p className="text-sm text-slate-500">Station</p>
                  <div className="mt-1">
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <Select
                        labelId="station-select-label"
                        id="station-select"
                        value={shiftData.info.station_id}
                        name="station_id"
                        onChange={handleStationChange}
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        }}
                      >
                        {generateStationOptions(shiftData.info.role_id)}
                      </Select>
                    </FormControl>
                  </div>
                </Box>
              </div>
            )}
        </div>
        <div className="w-full flex gap-2 mt-5">
          <div className="w-[132px] sm:col-span-1">
            <p className="text-sm text-slate-500">Shift start</p>
            <div className="mt-1">
              <FormControl fullWidth size="small">
                <Autocomplete
                  id="shift-start"
                  disableClearable
                  options={generateTimeOptions(props.start_qhnum)}
                  value={shiftInfoString.shift_start}
                  onChange={(event, newValue) => {
                    handleShiftInfo({
                      target: { name: "shift_start", value: newValue },
                    });
                  }}
                  filterOptions={filterOptions}
                  size="small"
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.key === "-" ||
                      event.key === "Enter" ||
                      event.key === "Tab"
                    ) {
                      event.preventDefault(); // Prevent the default dash behavior

                      // Select the first option from the autocomplete list
                      const firstOption = filterOptions(
                        generateTimeOptions(props.start_qhnum),
                        { inputValue: event.target.value }
                      )[0];

                      if (firstOption) {
                        // Set the first option as the selected value
                        handleShiftInfo({
                          target: { name: "shift_start", value: firstOption },
                        });

                        // Move focus directly to the Shift End input field by using its ID
                        const shiftEndInput =
                          document.getElementById("shift-end");
                        if (shiftEndInput) {
                          setTimeout(() => {
                            shiftEndInput.focus(); // Move focus to the Shift End input field
                            shiftEndInput.select();
                          }, 0); // Adding a small timeout to ensure the DOM is ready
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px !important",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                          backgroundColor: "white",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex justify-center items-center text-slate-400 mt-6">
            <ArrowForwardIcon />
          </div>
          <div className="w-[132px]">
            <p className="text-sm text-slate-500">Shift end</p>
            <div className="mt-1">
              <FormControl fullWidth size="small">
                <Autocomplete
                  id="shift-end"
                  disableClearable
                  options={generateTimeOptions(props.start_qhnum)}
                  value={shiftInfoString.shift_end}
                  onChange={(event, newValue) => {
                    handleShiftInfo({
                      target: { name: "shift_end", value: newValue },
                    });
                  }}
                  filterOptions={filterOptions}
                  size="small"
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                  // Handle key down event to select the first item and move focus to next field
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && event.target.value) {
                      event.preventDefault(); // Prevent the default tab/enter behavior
                      const firstOption = filterOptions(
                        generateTimeOptions(props.start_qhnum),
                        { inputValue: event.target.value }
                      )[0];
                      if (firstOption) {
                        handleShiftInfo({
                          target: { name: "shift_end", value: firstOption },
                        });

                        // Move focus directly to the Shift End input field by using its ID
                        const breakChecked =
                          document.getElementById("break-checked");
                        if (breakChecked) {
                          setTimeout(() => {
                            breakChecked.focus(); // Move focus to the Shift End input field
                          }, 0); // Adding a small timeout to ensure the DOM is ready
                        }
                      }
                    }
                    if (event.key === "Tab" && event.target.value) {
                      event.preventDefault(); // Prevent the default tab/enter behavior
                      const firstOption = filterOptions(
                        generateTimeOptions(props.start_qhnum),
                        { inputValue: event.target.value }
                      )[0];
                      if (firstOption) {
                        handleShiftInfo({
                          target: { name: "shift_end", value: firstOption },
                        });

                        // Move focus directly to the Shift End input field by using its ID
                        const saveShift = document.getElementById("save-shift");
                        if (saveShift) {
                          setTimeout(() => {
                            saveShift.focus(); // Move focus to the Shift End input field
                          }, 0); // Adding a small timeout to ensure the DOM is ready
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px !important",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                          backgroundColor: "white",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="w-16 flex flex-col justify-center items-center text-slate-400 text-xs mt-6">
            <ScheduleIcon style={{ padding: "0px 5px" }} />{" "}
            {shiftData.info.shift_end - shiftData.info.shift_start + " hrs"}
          </div>
        </div>
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center w-[120px]">
              <FormControlLabel
                control={
                  <Checkbox
                    id="break-checked"
                    name="breakChecked"
                    checked={breakChecked}
                    onChange={handleCheckChange}
                    size="small"
                    sx={{
                      color: "#6d28d9",
                      "&.Mui-checked": {
                        color: "#6d28d9",
                      },
                    }}
                  />
                }
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Prevent default Enter behavior
                    handleCheckChange({
                      target: { name: "breakChecked", checked: !breakChecked },
                    });
                  }
                }}
              />

              <p className="text-slate-900 text-sm -ml-3">Add break</p>
            </div>
            {props.p.has_break_waiver && (
              <div className="flex items-center gap-x-1">
                <div className="text-amber-600">
                  <WarningIcon
                    style={{ fontSize: "16px", marginBottom: "5px" }}
                  />
                </div>
                <p className="text-xs text-amber-600">
                  {props.p.first_name} has signed a break waiver
                </p>
              </div>
            )}
          </div>
          {breakChecked && (
            <div className="w-full flex gap-2 mt-1 pb-2">
              <div className="w-[132px]">
                <p className="text-sm text-slate-500">Break start</p>
                <div className="mt-1">
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="break_start"
                      name="break_start"
                      disableClearable
                      options={generateBreakOptions(
                        text_to_num(shiftInfoString.shift_start),
                        text_to_num(shiftInfoString.shift_end)
                      )}
                      value={breakInfoString.break_start}
                      onChange={(event, newValue) => {
                        handleBreakInfo({
                          target: { name: "break_start", value: newValue },
                        });
                      }}
                      filterOptions={filterOptions}
                      size="small"
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                      // Handle key down event to select the first item and move focus to next field
                      onKeyDown={(event) => {
                        if (
                          (event.key === "Enter" ||
                            event.key === "-" ||
                            event.key === "Tab") &&
                          event.target.value
                        ) {
                          event.preventDefault(); // Prevent the default tab/enter behavior
                          const firstOption = filterOptions(
                            generateBreakOptions(
                              text_to_num(shiftInfoString.shift_start),
                              text_to_num(shiftInfoString.shift_end)
                            ),
                            { inputValue: event.target.value }
                          )[0];
                          if (firstOption) {
                            handleBreakInfo({
                              target: {
                                name: "break_start",
                                value: firstOption,
                              },
                            });

                            // Move focus directly to the Shift End input field by using its ID
                            const breakEnd =
                              document.getElementById("break-end");
                            if (breakEnd) {
                              setTimeout(() => {
                                breakEnd.focus(); // Move focus to the Shift End input field
                                breakEnd.select(); // Select the current text so it's highlighted and ready to be overwritten
                              }, 0); // Adding a small timeout to ensure the DOM is ready
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px !important",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              backgroundColor: "white",
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="flex justify-center items-center text-slate-400 mt-6">
                <ArrowForwardIcon />
              </div>
              <div className="w-[132px]">
                <p className="text-sm text-slate-500">Break end</p>
                <div className="mt-1">
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="break-end"
                      name="break_end"
                      disableClearable
                      options={generateBreakOptions(
                        text_to_num(shiftInfoString.shift_start),
                        text_to_num(shiftInfoString.shift_end)
                      )}
                      value={breakInfoString.break_end}
                      onChange={(event, newValue) => {
                        handleBreakInfo({
                          target: { name: "break_end", value: newValue },
                        });
                      }}
                      filterOptions={filterOptions}
                      size="small"
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                      // Handle key down event to select the first item and move focus to next field
                      onKeyDown={(event) => {
                        if (
                          (event.key === "Enter" || event.key === "Tab") &&
                          event.target.value
                        ) {
                          event.preventDefault(); // Prevent the default tab/enter behavior
                          const firstOption = filterOptions(
                            generateBreakOptions(
                              text_to_num(shiftInfoString.shift_start),
                              text_to_num(shiftInfoString.shift_end)
                            ),
                            { inputValue: event.target.value }
                          )[0];
                          if (firstOption) {
                            handleBreakInfo({
                              target: { name: "break_end", value: firstOption },
                            });
                            // Move focus directly to the Shift End input field by using its ID
                            const noteChecked =
                              document.getElementById("note-checked");
                            if (noteChecked) {
                              setTimeout(() => {
                                noteChecked.focus(); // Move focus to the Shift End input field
                              }, 0); // Adding a small timeout to ensure the DOM is ready
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px !important",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              backgroundColor: "white",
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="w-16 flex flex-col justify-center items-center text-slate-400 text-xs mt-6">
                <ScheduleIcon style={{ padding: "0px 5px" }} />{" "}
                {shiftData.info.break_length + " hrs"}
              </div>
            </div>
          )}
        </div>
        <div className="mt-2">
          <div className="w-full flex items-center">
            <FormControlLabel
              control={
                <Checkbox
                  id="note-checked"
                  name="noteChecked"
                  checked={noteChecked}
                  onChange={handleNoteCheckChange}
                  size="small"
                  sx={{
                    color: "#6d28d9",
                    "&.Mui-checked": {
                      color: "#6d28d9",
                    },
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault(); // Prevent default Enter behavior
                      handleNoteCheckChange({
                        target: { name: "noteChecked", checked: !noteChecked },
                      });
                    }
                  }}
                />
              }
            />
            <p className="text-slate-900 text-sm -ml-3">Add note</p>
          </div>
          {noteChecked && (
            <div className="w-full pb-2">
              <div className="w-full">
                <p className="text-sm text-slate-500">Note</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Add a note to employee's shift card"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="note"
                    value={shiftData.info.note}
                    onChange={handleNoteChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShiftSingleInputModal;
