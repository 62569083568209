import React from 'react'
import Rating from '@mui/material/Rating';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RoleProficiencyThresholdModal from './RoleProficiencyThresholdModal';

function RoleProficiencyThresholdsDisplay(props) {

  const [openRoleProficiencyThresholdModal, setOpenRoleProficiencyThresholdModal] = React.useState(false);
  const handleOpenRoleProficiencyThresholdModal = () => setOpenRoleProficiencyThresholdModal(true);
  const handleCloseRoleProficiencyThresholdModal = () => setOpenRoleProficiencyThresholdModal(false);

  const [selectedRoleName, setSelectedRoleName] = React.useState(null)

  const default_data_proficiency_threshold = {
    role_id: null,
    star_rating: 0,
    learning_module_uuids: []
  }
  const [modalDataProficiencyThreshold, setModalDataProficiencyThreshold] = React.useState(default_data_proficiency_threshold)

  console.log("ROLE THRESHOLDS", props.learningModulesByRole)

  console.log("MODAL DATA PROFICIENCY THRESHOLD", modalDataProficiencyThreshold)

  return (
    <>
      <div className="mt-12 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-[25%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                    >
                      Role
                    </th>
                    {/* <th
                      scope="col"
                      className="w-[20%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Hire Date
                    </th> */}
                    {/* <th
                      scope="col"
                      className="w-[18%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      # of Employees Assigned
                    </th> */}
                    {/* <th
                      scope="col"
                      className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Training Health
                    </th> */}
                    <th
                      scope="col"
                      className="flex-1 pl-3 pr-12 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Role Proficiency Thresholds
                    </th>
                    {/* <th
                      scope="col"
                      className="w- relative py-3.5 pl-3 pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 bg-white">
                  {props.learningModulesByRole.map((role, index) => (
                    <tr
                      key={index}
                      // onClick={() => {
                      //   setShowStoreTraining(true)
                      // }}
                    >
                      <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                        <div>{role.role_name}</div>
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                        <div>June 1st, 2024</div>
                      </td> */}
                      {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                        <div>5</div>
                      </td> */}
                      {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                        <div className="w-fit">
                          <div className={`rounded-lg px-2 py-1 bg-emerald-50 border border-emerald-100 flex items-center justify-center`}>
                            <p className={"text-emerald-800"}>Good</p>
                          </div>
                        </div>
                      </td> */}
                      <td className="whitespace-nowrap pl-3 pr-12 py-5 text-sm text-slate-900">
                        {Object.entries(role.modules_through).map(([key, value]) => (
                          <div
                            key={key}
                            className="flex items-center gap-x-6 mb-3"
                          >
                            <div>
                              <Rating name="read-only" value={parseFloat(key)} precision={0.5} readOnly max={props.proficiencyStarsCount} />
                            </div>
                            <p className="text-slate-500">if employee completes</p>
                            <div className="flex flex-wrap gap-2">
                              {value.map((row, index) => (
                                <div
                                  key={index}
                                  className={`${row.learning_module.color} text-white px-5 py-2 rounded-lg`}
                                >
                                  {row.learning_module.name}
                                </div>
                              ))}
                            </div>
                            <div
                              className="w-10 h-10 rounded-full bg-slate-50 hover:bg-slate-100 flex items-center justify-center text-slate-500 cursor-pointer"
                              onClick={() => {
                                setModalDataProficiencyThreshold({
                                  role_id: role.id,
                                  star_rating: key,
                                  learning_module_uuids: value.map((module, index) => module.learning_module.uuid)
                                })
                                setSelectedRoleName(role.role_name)
                                handleOpenRoleProficiencyThresholdModal()
                              }}
                            >
                              <EditIcon style={{ fontSize: "20px" }} />
                            </div>
                          </div>
                        ))}
                        <div
                          className="w-fit px-8 h-9 bg-violet-50 hover:bg-violet-100 rounded-lg text-violet-700 flex items-center justify-center space-x-1 mt-4 cursor-pointer"
                          onClick={() => {
                            setModalDataProficiencyThreshold({
                              ...default_data_proficiency_threshold,
                              role_id: role.id
                            })
                            setSelectedRoleName(role.role_name)
                            handleOpenRoleProficiencyThresholdModal()
                          }}
                        >
                          <div>
                            <AddIcon style={{ fontSize: "20px", marginBottom: "1px" }} />
                          </div>
                          <p>Add Threshold to {role.role_name}</p>
                        </div>
                      </td>
                      {/* <td className="relative whitespace-nowrap py-5 pl-6 pr-6 text-right text-sm font-medium w-5">
                        <div className="flex items-center justify-end w-fit h-full text-slate-500">
                          <ChevronRightIcon />
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="border-b border-slate-200"></div>
            </div>
          </div>
        </div>
      </div>
      <RoleProficiencyThresholdModal
        open={openRoleProficiencyThresholdModal}
        handleClose={handleCloseRoleProficiencyThresholdModal}
        modalDataProficiencyThreshold={modalDataProficiencyThreshold}
        setModalDataProficiencyThreshold={setModalDataProficiencyThreshold}
        default_data_proficiency_threshold={default_data_proficiency_threshold}
        post_learning_module_star_threshold={props.post_learning_module_star_threshold}
        selectedRoleName={selectedRoleName}
        homeCompany={props.homeCompany}
        proficiencyStarsCount={props.proficiencyStarsCount}
      />
    </>
  )
}

export default RoleProficiencyThresholdsDisplay
