import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

// Function to translate user input into a standardized format
// Function to translate user input into a standardized format
const translateInput = (input) => {
  const inputStr = input.toString().toLowerCase(); // Ensure input is treated as a string
  console.log("Input received:", inputStr); // Check the received input

  const amPmMatch = inputStr.match(/(a|am|p|pm)$/); // Check if input ends with a, am, p, or pm
  let timePart = timeToDigits(inputStr); // Remove am/pm from the time part
  const isPM = amPmMatch && (amPmMatch[0] === "p" || amPmMatch[0] === "pm");
  const isAM = amPmMatch && (amPmMatch[0] === "a" || amPmMatch[0] === "am");

  if (timePart.length === 1) {
    const hour = parseInt(timePart.substring(0, 1), 10);
  }

  // Handle inputs with 3 digits (e.g., 245, 245p -> 2:45am/pm)
  if (timePart.length === 3) {
    const hour = parseInt(timePart.substring(0, 1), 10);
    const minute = timePart.substring(1);
    const formattedTime = `${hour % 12}:${minute.padEnd(2, "0")}${
      isPM ? "pm" : "am"
    }`;
    return formattedTime;
  }

  // Handle inputs with 4 digits (e.g., 1245, 1115a -> 12:45pm/11:15am)
  if (timePart.length === 4) {
    const hour = parseInt(timePart.substring(0, 2), 10);
    const minute = timePart.substring(2);
    const formattedTime = `${hour % 12 || 12}:${minute.padEnd(2, "0")}${
      isPM ? "pm" : isAM ? "am" : ""
    }`;
    return formattedTime;
  }

  // Translate inputs like "2p" to "2:00 PM"
  if (timePart.length < 3) {
    const hour = parseInt(timePart, 10);
    return `${hour % 12 || 12}:00${isPM ? "pm" : "am"}`; // Convert to 12-hour format with AM/PM
  }

  return inputStr; // Return the input as is if no translation rule applies
};

// Function to translate a time into a digit-only string for matching (e.g., "2:45am" -> "245")
const timeToDigits = (time) => {
  return time.replace(/[^0-9]/g, ""); // Remove non-numeric characters
};

// Custom filter logic using the translateInput function
export const filterOptions = (options, { inputValue }) => {
  console.log("Filtering options for:", inputValue); // Check the inputValue before translation

  const inputDigits = inputValue.replace(/[^0-9]/g, ""); // Extract digits from the input
  const isAM = inputValue.toLowerCase().includes("a");
  const isPM = inputValue.toLowerCase().includes("p");

  if (isAM || isPM) {
    const translatedInput = translateInput(inputValue);
    const exactOptions = options.filter((option) => {
      return option === translatedInput;
    });
    if (exactOptions.length > 0) {
      return [exactOptions[0]];
    }
  }

  //console.log("Translated input:", translatedInput); // Check the translated input
  const filteredOptions = options.filter((option) => {
    //option.toLowerCase().includes(translatedInput.toLowerCase())
    const timeDigits = timeToDigits(option);

    // Check if AM or PM filter is applied
    if (isAM && !option.toLowerCase().includes("am")) return false;
    if (isPM && !option.toLowerCase().includes("pm")) return false;

    return timeDigits.includes(inputDigits); // Match digits
  });
  return filteredOptions;
};

export function get_t_str(value) {
  let hh_num_temp = value % 24;
  if (value == 24) {
    return "12:00am";
  }
  const hh_num = Math.round(hh_num_temp * 4) / 4;
  var hour_num = Math.trunc(hh_num);
  const daypart = hour_num >= 12 ? "pm" : "am";
  hour_num = hour_num > 12 ? (hour_num -= 12) : hour_num;
  const hh_str = hh_num % 1 === 0 ? ":00" : ":" + (hh_num % 1) * 60;

  let next_day = "";
  if (value > 24) {
    next_day = "(n)";
  }

  return hour_num + hh_str + daypart + next_day;
}

export function get_t_str_shortened(value) {
  let hh_num_temp = value % 24;
  if (value == 24) {
    return "12:00a";
  }
  const hh_num = Math.round(hh_num_temp * 4) / 4;
  var hour_num = Math.trunc(hh_num);
  const daypart = hour_num >= 12 ? "p" : "a";
  hour_num = hour_num > 12 ? (hour_num -= 12) : hour_num;
  const hh_str = hh_num % 1 === 0 ? ":00" : ":" + (hh_num % 1) * 60;

  let next_day = "";
  if (value > 24) {
    next_day = "(n)";
  }

  return hour_num + hh_str + daypart + next_day;
}

export function get_t_str_shortened_max(value) {
  let hh_num_temp = value % 24;
  if (value == 24) {
    return "12a";
  }
  const hh_num = Math.round(hh_num_temp * 4) / 4;
  var hour_num = Math.trunc(hh_num);
  const daypart = hour_num >= 12 ? "p" : "a";
  hour_num = hour_num > 12 ? (hour_num -= 12) : hour_num;
  const hh_str = hh_num % 1 === 0 ? ":00" : ":" + (hh_num % 1) * 60;

  let next_day = "";
  if (value > 24) {
    next_day = "(n)";
  }

  return hour_num + daypart;
}

export function generateLaborSummary(
  forecasted,
  actual,
  get_t_str,
  label1,
  label2
) {
  const laborSummary = {};
  const roleTypes = new Set();

  // Process forecasted labor
  for (const forecastedObj of forecasted) {
    const { qh_num, role_type, labor, hours } = forecastedObj;
    const time = get_t_str(qh_num);
    roleTypes.add(role_type); // Add role_type to the set of roleTypes

    if (!laborSummary[qh_num]) {
      laborSummary[qh_num] = {
        qh_num,
        time,
        [`${label1} Non Flexible Total`]: labor,
        [`${label2} Labor Total`]: 0,
        [`${label1} Flexible Total`]: hours * 4 - labor,
      };
    } else {
      laborSummary[qh_num][`${label1} Non Flexible Total`] += labor;
      laborSummary[qh_num][`${label1} Flexible Total`] += hours * 4 - labor;
    }

    if (!laborSummary[qh_num][`${role_type} ${label1} Non Flexible`]) {
      laborSummary[qh_num][`${role_type} ${label1} Non Flexible`] = labor;
    } else {
      laborSummary[qh_num][`${role_type} ${label1} Non Flexible`] += labor;
    }

    if (!laborSummary[qh_num][`${role_type} ${label1} Flexible`]) {
      laborSummary[qh_num][`${role_type} ${label1} Flexible`] =
        hours * 4 - labor;
    } else {
      laborSummary[qh_num][`${role_type} ${label1} Flexible`] +=
        hours * 4 - labor;
    }
  }

  // Process actual scheduled labor
  for (const actualObj of actual) {
    const { qh_num, role_type, labor } = actualObj;
    const time = get_t_str(qh_num);
    roleTypes.add(role_type); // Add role_type to the set of roleTypes

    if (!laborSummary[qh_num]) {
      laborSummary[qh_num] = {
        qh_num,
        time,
        [`${label1} Non Flexible Total`]: 0,
        [`${label2} Labor Total`]: labor,
        [`${label1} Flexible Total`]: 0,
      };
    } else {
      laborSummary[qh_num][`${label2} Labor Total`] += labor;
    }

    if (!laborSummary[qh_num][`${role_type} ${label2} Labor`]) {
      laborSummary[qh_num][`${role_type} ${label2} Labor`] = labor;
    } else {
      laborSummary[qh_num][`${role_type} ${label2} Labor`] += labor;
    }
  }

  // Sort the resulting array by qh_num
  const sortedSummary = Object.values(laborSummary).sort(
    (a, b) => a.qh_num - b.qh_num
  );

  // Filter to only include qh_nums where Total Scheduled Labor and Total Forecasted Non Flexible are both above 0
  const filteredSummary = sortedSummary.filter(
    ({
      [`${label2} Labor Total`]: scheduled,
      [`${label1} Non Flexible Total`]: forecasted,
      [`${label1} Flexible Total`]: hours,
    }) => scheduled > 0 || forecasted > 0 || hours > 0
  );

  return { summary: filteredSummary, roleTypes: Array.from(roleTypes) };
}

// Function that gets the role type
export const getRoleType = (role_id, role_lookups) => {
  const role_info = role_lookups.filter((r) => r.id === role_id);
  if (role_info.length > 0) {
    return role_info[0]["role_type"];
  } else {
    return null;
  }
};

export const getRoleName = (role_id, role_lookups) => {
  const role_info = role_lookups.filter((r) => r.id === role_id);
  if (role_info.length > 0) {
    return role_info[0]["role_name"];
  } else {
    return null;
  }
};

// Sets the shift card color based on the the type of role
export function getCardColor(role_id, roleNames, roleTypes, lockedMode) {
  const roleType = getRoleType(role_id, roleNames);

  return getColorFromRoleType(roleType, roleTypes, lockedMode);
}

const getLockedColorLookup = {
  "bg-violet-600": "bg-violet-700",
  "bg-sky-700": "bg-sky-800",
  "bg-cyan-600": "bg-cyan-700",
  "bg-emerald-600": "bg-emerald-700",
  "bg-lime-600": "bg-lime-700",
  "bg-amber-500": "bg-amber-600",
  "bg-orange-600": "bg-orange-700",
  "bg-red-600": "bg-red-700",
  "bg-pink-600": "bg-pink-700",
};

export function getCardColor2(role_id, roleNames, lockedMode, shiftCard) {
  const foundRole = roleNames.find((role) => role.id === role_id);

  if (!foundRole)
    return lockedMode
      ? "bg-slate-700"
      : `bg-slate-600 ${shiftCard ? "cursor-pointer" : ""}`;

  if (foundRole.color === null)
    return lockedMode
      ? "bg-slate-700"
      : `bg-slate-600 ${shiftCard ? "cursor-pointer" : ""}`;

  return lockedMode
    ? getLockedColorLookup[foundRole.color]
    : `${foundRole.color} ${shiftCard ? "cursor-pointer" : ""}`;
}

export function getColorFromRoleType(roleType, roleTypes, lockedMode) {
  const colorList = [
    ["bg-orange-600", "bg-orange-700"],
    ["bg-sky-700", "bg-sky-800"],
  ];

  // Default colors if the roleType is not found
  let baseColor = "bg-gray-700";
  let hoverColor = "bg-gray-800";

  const roleTypesArr = roleTypes.map((role) => role.role_type); // Assuming roleNames is an array of objects with role_type attribute

  // Find the index of the roleType in the roleTypes array
  const index = roleTypesArr.indexOf(roleType);

  // If the roleType is found, use its index to get the corresponding color from colorList
  // If the colorList is not long enough, restart from the beginning using the modulo operator
  if (index !== -1) {
    const colorIndex = index % colorList.length;
    [baseColor, hoverColor] = colorList[colorIndex];
  }

  return lockedMode
    ? hoverColor
    : `${baseColor} hover:${hoverColor} cursor-pointer`;
}

export function colorLookup(tailwind_color) {
  const colors = {
    "bg-orange-700": "#ea580c",
    "bg-sky-800": "#0369a1",
    "bg-gray-800": "#4a5568",
  };
  return colors[tailwind_color];
}

export function convertBarColor(tailwind_color) {
  const colors = {
    "bg-orange-700": "#fff7ed",
    "bg-sky-800": "#eff6ff",
    "bg-gray-800": "#f9fafb",
  };
  return colors[tailwind_color];
}

export function convertBorderColor(tailwind_color) {
  const colors = {
    "bg-orange-700": "#fb923c",
    "bg-sky-800": "#60a5fa",
    "bg-gray-800": "#9ca3af",
  };
  return colors[tailwind_color];
}

export const text_to_num = (text) => {
  if (text === "12:00am") {
    return 24;
  }
  let arr = text.split(":");
  let adj = 0;
  let hours = parseInt(text);
  if (text.toLowerCase().includes("p") & (hours !== 12)) {
    adj += 12;
  }
  if (text.toLowerCase().includes("a") & (hours === 12)) {
    adj -= 12;
  }
  if (text.toLowerCase().includes("n")) {
    adj += 24;
  }
  let num = 0;
  if ((text.length === 0) | isNaN(hours)) {
    num = 0;
  } else if (arr.length > 1) {
    let minutes =
      (arr[1].length > 0) & !isNaN(parseInt(arr[1]))
        ? parseInt(arr[1]) / 60
        : 0;
    num = adj + hours + minutes;
  } else {
    num = parseInt(text) + adj;
  }
  return Math.max(Math.round(num * 4) / 4, 0);
};

export const checkIfValid = (state, break_checked) => {
  let valid = true;
  if (state.shift_end <= state.shift_start) {
    valid = false;
  }
  if (state.shift_end - state.shift_start >= 16) {
    valid = false;
  }
  if (break_checked & (state.break_start <= state.shift_start)) {
    valid = false;
  }
  if (
    break_checked &
    (state.break_start + state.break_length >= state.shift_end)
  ) {
    valid = false;
  }
  if (break_checked & (state.break_length >= 8)) {
    valid = false;
  }
  if (state.break_length < 0) {
    valid = false;
  }
  return valid;
};

export function createDayArray(startWeekIndex) {
  const allDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const rotatedDays = [];

  for (let i = 0; i < allDays.length; i++) {
    rotatedDays.push(allDays[(i + startWeekIndex) % allDays.length]);
  }

  return rotatedDays;
}

export function process_schedule_data(
  schedule,
  store_team_member_weekly_info,
  role_names
) {
  const schedule_data = schedule
    .filter((s, i) => s.other_store != true)
    .map((x) => {
      const color_obj = role_names.find((v, i) => v.id === x.role_id);
      x.color = color_obj ? color_obj.color : "";
      x.pre_shift = x.shift_start - 0;
      if (x.break_length > 0) {
        x.pre_break = x.break_start - x.shift_start;
        x.break = x.break_length;
        x.post_break = x.shift_end - (x.break_start + x.break_length);
      } else {
        x.pre_break = x.shift_length;
        x.break = 0;
        x.post_break = 0;
      }
      x.post_shift = 24 - x.shift_end;
      const person_info = store_team_member_weekly_info.filter(
        (p) => p.id === x.store_team_member
      );
      x.name =
        person_info.length > 0
          ? person_info[0].first_name + " " + person_info[0].last_name[0] + "."
          : "";

      return x;
    });

  return schedule_data.sort(
    (a, b) => a.shift_start > b.shift_start || a.shift_length > b.shift_length
  );
}

export function generateTimeOptionsDF(start_qhnum) {
  const options = [];
  const startTime = 0;
  const endTime = 24 + start_qhnum;

  let currentTime = startTime;

  while (currentTime <= endTime) {
    options.push({ value: currentTime, label: get_t_str(currentTime) });
    currentTime += 0.25;
  }

  return options;
}

export function generateBufferOptionsDF() {
  const options = [];
  const startTime = -3;
  const endTime = 3;

  let currentTime = startTime;

  while (currentTime <= endTime) {
    options.push({ value: currentTime, label: `${currentTime * 60} mins` });
    currentTime += 0.25;
  }

  return options;
}

export function generateTimeOptions(start_qhnum) {
  const options = [];
  const startTime = 0;
  const endTime = 24 + start_qhnum;

  let currentTime = startTime;

  while (currentTime <= endTime) {
    options.push(get_t_str(currentTime));
    currentTime += 0.25;
  }

  return options;
}

export function getColorFromGrade(grade) {
  if (grade === "A") {
    return "#059669";
  } else if (grade === "B") {
    return "#65a30d";
  } else if (grade === "C") {
    return "#f5be27";
  } else if (grade === "D") {
    return "#ea580c";
  } else if (grade === "F") {
    return "#dc2626";
  } else {
    return "gray";
  }
}

export const PurpleSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#6d28d9",
    "&:hover": {
      backgroundColor: alpha("#6d28d9", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#6d28d9",
  },
}));

export function createWarningsTooltip(numScheduleWarnings, countConflicts) {
  let tooltipText = "";
  if (numScheduleWarnings > 0) {
    tooltipText += `${numScheduleWarnings} ${
      numScheduleWarnings === 1 ? "person is" : "people are"
    } in overtime`;
  }
  if (countConflicts > 0) {
    if (numScheduleWarnings > 0) {
      tooltipText += ", ";
    }
    tooltipText += `${countConflicts} ${
      countConflicts === 1 ? "person has" : "shifts with"
    } an availability conflict`;
  }
  return tooltipText;
}

export function formatName(inputName) {
  if (!inputName) {
    return "";
  }
  // Split the input name into parts
  const nameParts = inputName.split(" ");

  // Capitalize the first letter of each part and convert the rest to lowercase
  const formattedNameParts = nameParts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );

  // Join the formatted parts back together
  const formattedName = formattedNameParts.join(" ");

  return formattedName;
}

export function formatTimestamp(isoString) {
  const date = new Date(isoString);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutesStr + ampm;
}

export function formatTimestampWithDate(isoString) {
  const date = new Date(isoString);

  // Format the date as MM/DD/YYYY or use your preferred format
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  // Combine the date and time
  return `${hours}:${minutesStr}${ampm} ${month}/${day}/${year}`;
}
