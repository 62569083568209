import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Rating from '@mui/material/Rating';
import LoadSpinner from '../../../utilities/LoadSpinner';
import { API_get_learning_modules } from '../../../constants';
import { getRightworkAPI } from '../requests/utilities/requests';

function RoleProficiencyThresholdModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: 350,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [modulesList, setModulesList] = useState(null);
  const [selectedModuleNames, setSelectedModuleNames] = useState([]);
  const [selectedModuleUUIDs, setSelectedModuleUUIDs] = useState([]);

  const fetchModulesList = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
    };
    try {
      const res = await getRightworkAPI(API_get_learning_modules, api_params);
      if (res.status === 200) {
        setModulesList(res.data.redux?.payload?.learning_modules);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  useEffect(() => {
    if (props.open) {
      fetchModulesList(true);
    }
  }, [props.open]);

  useEffect(() => {
    if (modulesList && props.modalDataProficiencyThreshold.learning_module_uuids) {
      const selectedNames = props.modalDataProficiencyThreshold.learning_module_uuids.map(uuid => {
        const module = modulesList.find(module => module.uuid === uuid);
        return module ? module.name : '';
      });
      setSelectedModuleUUIDs(props.modalDataProficiencyThreshold.learning_module_uuids);
      setSelectedModuleNames(selectedNames);
    }
  }, [modulesList, props.modalDataProficiencyThreshold]);

  function handleStarRatingsChange(event, newValue) {
    props.setModalDataProficiencyThreshold({
      ...props.modalDataProficiencyThreshold,
      star_rating: newValue,
    });
  }

  function handleModuleChange(event) {
    const selectedUUIDs = event.target.value;
    const selectedNames = selectedUUIDs.map(uuid => {
      const module = modulesList.find(module => module.uuid === uuid);
      return module ? module.name : '';
    });

    setSelectedModuleUUIDs(selectedUUIDs);
    setSelectedModuleNames(selectedNames);
    props.setModalDataProficiencyThreshold({
      ...props.modalDataProficiencyThreshold,
      learning_module_uuids: selectedUUIDs,
    });
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.handleClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-2 top-2 text-gray-500 cursor-pointer"
          onClick={() => props.handleClose()}
        >
          <CloseIcon />
        </div>
        {!isLoading ?
          <div>
            <div>
              <h3 className="text-2xl text-slate-900">Add Proficiency Threshold to {props.selectedRoleName}</h3>
            </div>
            <div className="mt-5">
              <p className="text-slate-500 text-sm">Select number of stars awarded on completion</p>
              <div className="mt-2">
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={props.modalDataProficiencyThreshold.star_rating}
                  precision={0.5}
                  onChange={handleStarRatingsChange}
                  max={props.proficiencyStarsCount}
                />
              </div>
            </div>
            <div className="mt-3">
              <p className="text-slate-500 text-sm mt-4">Which modules need to be completed to achieve above proficiency?</p>
              <div className="flex-1 mt-2">
                <div className="w-full">
                  <Select
                    fullWidth
                    multiple
                    value={selectedModuleUUIDs}
                    onChange={handleModuleChange}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select module(s) needed to complete</em>;
                      }
                      return selectedModuleNames.join(', ');
                    }}
                    sx={{
                      borderRadius: "8px",
                      paddingY: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select module(s) needed to complete</em>
                    </MenuItem>
                    {modulesList?.map((module) => (
                      <MenuItem key={module.uuid} value={module.uuid}>
                        {module.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div
                  className="px-8 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.post_learning_module_star_threshold(props.modalDataProficiencyThreshold.role_id, props.modalDataProficiencyThreshold.star_rating, JSON.stringify(props.modalDataProficiencyThreshold.learning_module_uuids))
                    props.handleClose();
                  }}
                >
                  Add Threshold to Server
                </div>
              </div>
            </div>
          </div> :
          <div className="w-full h-[350px]">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <LoadSpinner />
            </div>
          </div>
        }
      </Box>
    </Modal>
  );
}

export default RoleProficiencyThresholdModal;
