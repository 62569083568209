import * as React from "react";
import ShiftInputsModal from "./ShiftInputsModal";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import LoadSpinner from "../../../../utilities/LoadSpinner";
import NoCapableRolesWarningModal from "./NoCapableRolesWarningModal";
import { get_t_str } from "../../../../helpers/helpers";
import { get_t_str_shortened } from "../../../../helpers/helpers";
import { get_t_str_shortened_max } from "../../../../helpers/helpers";

function NewShiftCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  // Function for opening the modal
  const handleOpen = () => setOpen(true);

  // Function for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
  };

  const [showPasteButton, setShowPasteButton] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // Default shift info
  const shift_info = {
    adjustment_type: "no_adjust",
    break_length: 0,
    break_start: 12,
    date: props.date_str,
    hours: 4,
    id: 0,
    person: props.p.scheduling_app_user_id,
    role_id: props.p.main_role_id,
    role_type: props.p.role_type,
    station_id: null,
    station: null,
    shift_end: 16,
    shift_length: 4,
    shift_start: 12,
    store: props.store_id,
    store_team_member: props.p.id,
    note: null,
  };

  // Logic for determining the break in the New Shift component
  // Assuming availability_info is an array that may be empty or contain objects without defined properties
  const availableFrom =
    (props.availability_info && props.availability_info["available_from"]) || 0;
  const availableTo =
    (props.availability_info && props.availability_info["available_to"]) || 24;
  const availableTimeSpan = availableTo - availableFrom;

  let shiftStart = availableFrom;
  let shiftEnd = availableTo;

  if (availableTimeSpan > 8) {
    shiftEnd = shiftStart + 8;
  }

  let breakLength = 0;
  let breakStart = shiftStart;

  // Only set a break if the available time span is more than the threshold
  if (availableTimeSpan >= 6) {
    // Decide on the break length based on the available time span
    breakLength =
      availableTimeSpan > 6
        ? props.defaultBreakLengthHours
        : props.defaultBreakLengthHours;

    // Calculate the break start time based on the middle of the shift
    breakStart =
      Math.round(((shiftEnd + shiftStart) / 2) * 4) / 4 - breakLength / 2;
  }

  let dynamicShiftInfo = shift_info;
  if (availableFrom !== 0 || availableTo !== 24) {
    // Construct the dynamic shift object
    dynamicShiftInfo = {
      ...shift_info, // Keep other default shift_info properties
      shift_start: shiftStart,
      shift_end: shiftEnd,
      shift_length: shiftEnd - shiftStart,
      break_length: breakLength,
      break_start: breakStart,
      hours: shiftEnd - shiftStart - breakLength,
      // Other properties (id, person, role_id, role_type, store) should be derived from props as before
    };
  }

  // Handle right-click context menu
  const handleContextMenu = (event) => {
    props.setCopiedShift(null);
    event.preventDefault();
    if (props.copiedShift) {
      setShowPasteButton((prevState) => !prevState);
    } else {
      setShowPasteButton(false);
    }
  };

  // Handle paste button click
  const handlePaste = (copiedShift) => {
    let new_shift = { ...copiedShift };
    if (props.p.primary_role_id) {
      if (new_shift["role_id"] !== props.p.primary_role_id) {
        new_shift["role_id"] = props.p.primary_role_id;
        new_shift["role_type"] = props.p.primary_role_type_name;
        new_shift["station"] = null;
        new_shift["station_id"] = null;
      }
    }
    new_shift["person"] = props.p.scheduling_app_user_id;
    new_shift["store_team_member"] = props.p.id;
    new_shift["store"] = props.store_id;
    new_shift["date"] = props.date_str;
    let appliedTo = [false, false, false, false, false, false, false];
    appliedTo[props.d] = true;
    setIsLoading(true);
    props.addShift(new_shift, "add", appliedTo);
    // props.setCopiedShift(null);
  };

  // Handle cut and paste
  const handleCutPaste = (copiedShift) => {
    let new_shift = { ...copiedShift };
    if (props.p.primary_role_id) {
      if (new_shift["role_id"] !== props.p.primary_role_id) {
        new_shift["role_id"] = props.p.primary_role_id;
        new_shift["role_type"] = props.p.primary_role_type_name;
        new_shift["station"] = null;
        new_shift["station_id"] = null;
      }
    }
    new_shift["person"] = props.p.scheduling_app_user_id;
    new_shift["store_team_member"] = props.p.id;
    new_shift["store"] = props.store_id;
    new_shift["date"] = props.date_str;
    new_shift["action"] = "add";

    copiedShift["action"] = "delete";
    setIsLoading(true);
    props.addMultipleShifts([copiedShift, new_shift]);
    props.setCopiedShift(null);
  };

  // Handle drop event when shift is dragged and dropped
  const handleDrop = (e, copiedShift) => {
    e.target.classList.remove("border");
    e.target.classList.remove("border-emerald-500");
    handleCutPaste(copiedShift);
  };

  // Handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    const parentElement = e.currentTarget;
    parentElement.classList.remove("opacity-0");
    parentElement.classList.remove("border-2");
    parentElement.classList.remove("border-dashed");
    parentElement.classList.remove("border-slate-300");
    parentElement.classList.add("border-2");
    parentElement.classList.add("border-emerald-500");
  };

  // Handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    const parentElement = e.currentTarget;
    if (props.weekly_or_daily === "daily") {
      parentElement.classList.add("opacity-0");
    }
    parentElement.classList.remove("border-2");
    parentElement.classList.remove("border-emerald-500");
    parentElement.classList.add("border-2");
    parentElement.classList.add("border-dashed");
    parentElement.classList.add("border-slate-300");
  };

  React.useEffect(() => {
    if (props.saveShiftFinished) {
      setIsLoading(false);
    }
  }, [props.saveShiftFinished]);

  const [openNoCapableRolesWarning, setOpenNoCapableRolesWarning] =
    React.useState(false);
  const handleOpenNoCapableRolesWarning = () => {
    setOpenNoCapableRolesWarning(true);
  };
  const handleCloseNoCapableRolesWarning = () => {
    setOpenNoCapableRolesWarning(false);
  };

  let availabilityString = "";

  if (props.availability_info === undefined) {
    availabilityString = `All Day`;
  } else if (props.availability_info?.available_to_2) {
    availabilityString = `${get_t_str_shortened(
      props.availability_info?.available_from
    )} - ${get_t_str_shortened(props.availability_info?.available_to)} (+1)`;
  } else if (
    props.availability_info?.available_from === 0 &&
    props.availability_info?.available_to === 24
  ) {
    availabilityString = `All Day`;
  } else {
    availabilityString = `${get_t_str_shortened(
      props.availability_info?.available_from
    )} - ${get_t_str_shortened(props.availability_info?.available_to)}`;
  }

  return (
    <div className="w-full h-full relative">
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      ) : (
        <div className="w-full h-full relative">
          <a
            onClick={
              props.copiedShift
                ? props.copiedShift["copy_type"] === "cut"
                  ? () => handleCutPaste(props.copiedShift)
                  : () => handlePaste(props.copiedShift)
                : props.hasNoCapableRoles
                ? handleOpenNoCapableRolesWarning
                : handleOpen
            }
            className={
              "cursor-pointer hover:opacity-100 hover:border-violet-700 hover:border-solid hover:bg-white hover:text-violet-700 group w-full h-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-600 font-medium" +
              (props.weekly_or_daily === "daily" &&
                " hover:opacity-70 opacity-0")
            }
            onContextMenu={(event) => handleContextMenu(event)}
            onDrop={(e) => handleDrop(e, props.copiedShift)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {props.weekly_or_daily === "weekly" &&
              (props.copiedShift && !props.minimizeMode ? (
                <ContentPasteGoIcon
                  style={{
                    fontSize: "16px",
                    color: "currentColor",
                    fill: "currentColor",
                    marginTop: "5px",
                  }}
                />
              ) : (
                <svg
                  className="group-hover:text-violet-700 text-slate-400 mb-1"
                  width="20"
                  height="20"
                  fill="currentColor"
                  aria-hidden="true"
                  style={{ pointerEvents: "none" }}
                >
                  <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                </svg>
              ))}
            {props.weekly_or_daily === "weekly" && (
              <div>
                <p
                  style={{ pointerEvents: "none" }}
                  className={`text-xxs text-center ${
                    props.minimizeMode && "mb-[3px]"
                  }`}
                >
                  {props.copiedShift ? "Paste shift" : availabilityString}
                </p>
              </div>
            )}
            {props.weekly_or_daily === "daily" && (
              <div className="flex items-center justify-between">
                <p
                  style={{ pointerEvents: "none" }}
                  className={
                    props.weekly_or_daily === "daily"
                      ? "text-sm group-hover:text-blue-500"
                      : "text-xxs"
                  }
                >
                  {props.copiedShift ? "Paste shift" : "New shift"}
                </p>
                {props.copiedShift ? (
                  <ContentPasteGoIcon
                    style={{
                      fontSize: "16px",
                      marginLeft: "8px",
                      border: "none",
                    }}
                  />
                ) : (
                  <AddIcon
                    style={{
                      fontSize: "16px",
                      marginLeft: "8px",
                      border: "none",
                    }}
                  />
                )}
              </div>
            )}
          </a>
          <ShiftInputsModal
            open={open}
            handleClose={handleClose}
            shift_info={dynamicShiftInfo}
            shifts_info={[dynamicShiftInfo]}
            p={props.p}
            new_shift={true}
            addShift={props.addShift}
            date_name={props.date_name}
            get_t_str={props.get_t_str}
            availability_info={props.availability_info}
            role_names={props.role_names}
            saveShiftFinished={props.saveShiftFinished}
            setSaveShiftFinished={props.setSaveShiftFinished}
            d={props.d}
            role_types={props.role_types}
            addMultipleShifts={props.addMultipleShifts}
            businessWeekStartDayIndex={props.businessWeekStartDayIndex}
            start_qhnum={props.start_qhnum}
            frequentShifts={props.frequentShifts}
            breakWaiverEnabled={props.breakWaiverEnabled}
            person_day_time_off_requests={props.person_day_time_off_requests}
            defaultBreakLengthHours={props.defaultBreakLengthHours}
            breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
          />
          <NoCapableRolesWarningModal
            open={openNoCapableRolesWarning}
            handleClose={handleCloseNoCapableRolesWarning}
            p={props.p}
            role_names={props.role_names}
            updatePersonInfo={props.updatePersonInfo}
          />
        </div>
      )}
    </div>
  );
}

export default NewShiftCard;
